import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Image, ImageViewer, Modal, Toast } from 'antd-mobile-v5'
import ZCountDown from '../../components/zCountDown'
import ZFooterBar from '../../components/zFooterBar'
import { observer } from 'mobx-react'
import mainStore from '@/store/main'
import qs from 'query-string'
import './index.scss'
import { getTaskDetail, onTaskCancel, onTaskDelayPay, onTaskFinish } from '../../apis/task'
import { copy, formatPhoneNumber, showConfirm, timestampToString } from '../../utils'

const TaskDetail = () => {
    const { push } = useHistory()
    const { id } = qs.parse(useLocation().search)
    const [info, setInfo] = useState(null)

    useEffect(() => {
        if (!mainStore.info.id) {
            mainStore.getInfo()
        }
    }, [])

    useEffect(() => {
        if (id) {
            getData()
        }
    }, [])

    const getData = async() => {
        try {
            const { data } = await getTaskDetail({ id })
            setInfo(data)
        } catch (e) {
            console.log(e)
        }
    }

    const onCancel = async () => {
        const res = await showConfirm({ content: '是否确认退给用户？' })
        if (!res) return
        try {
            await onTaskCancel({ id })
            Toast.show('操作成功')
            getData()
        } catch (e) {
            console.log(e)
        }
    }

    const onDelayPay = async () => {
        const res = await showConfirm({ content: '是否确认暂停打款？' })
        if (!res) return
        try {
            await onTaskDelayPay({ id })
            Toast.show('操作成功')
            getData()
        } catch (e) {
            console.log(e)
        }
    }

    const onFinish = async () => {
        const res = await showConfirm({ content: '是否确认完成订单？' })
        if (!res) return
        try {
            await onTaskFinish({ id })
            Toast.show('操作成功')
            getData()
        } catch (e) {
            console.log(e)
        }
    }

    const showVideoViewer = (url) => {
        Modal.show({
            content: (<div>
                <video
                    src={url}
                    controls
                    style={{ display: 'block', width: '100%', height: 200, background: '#000' }}
                ></video>
            </div>),
            closeOnMaskClick: true,
        })
    }

    return (
        <div className="taskDetail">
            {!!info && <div className="content">
                <div className="info-card">
                    {info.status === 0 && <div className="block-title">将于<ZCountDown
                        className="timer"
                        value={info.count_down}
                        format="mm分ss秒"
                        onTimeEnd={getData}
                    />后自动取消订单</div>}
                    {info.status === 1 && <div className="block-title">将于<ZCountDown
                        className="timer"
                        value={info.order_refund_count_down}
                        format="dd天hh时mm分ss秒"
                        onTimeEnd={getData}
                    />后自动退款</div>}
                    {info.status === 3 && <div className="block-title">将于<ZCountDown
                        className="timer"
                        value={info.order_confirm_count_down}
                        format="dd天hh时mm分ss秒"
                        onTimeEnd={getData}
                    />后自动确认完成</div>}
                    <div className="card-header flex-space">
                        <div className="userInfo flex-a-center">
                            <div className="userInfo-media flex-shrink-0">
                                <Image className="userInfo-img" src={info.avatar} />
                            </div>
                            <div className="userInfo-inner">
                                <div className="userInfo-title">{info.nickname}</div>
                                <div className="userInfo-text">{info.date_diff}</div>
                            </div>
                        </div>
                        <div className="flex-a-center">
                            <div className="amount">￥{info.price}</div>
                            {info.status === 1 ? <div className="status-btn flex-center succ">
                                <span className="dot"></span>
                                <span>{info.status_html}</span>
                            </div> : <div className={`status-btn ${[2,3].includes(info.status)
                                ? 'succ'
                                : (info.status === 0 ? 'erro' : '')
                            }`}>{info.status_html}</div>}
                        </div>
                    </div>
                    <div className="card-inner">
                        <div className="card-title">{info.content}</div>
                        <div className="cells">
                            <div className="cell flex-a-center">
                                <div className="item-media flex-shrink-0">取</div>
                                <div className="item-inner flex-1">
                                    <div className="item-title ell1">{info.start_position_address}</div>
                                </div>
                            </div>
                            <div className="cell active flex-a-center">
                                <div className="item-media flex-shrink-0">收</div>
                                <div className="item-inner flex-1">
                                    <div className="item-title ell1">{info.end_position_address}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {info.help_user_id > 0 ? (
                    <div className="people-card">
                        <div className="flex flex-jsb">
                            <div className="userInfo flex">
                                <div className="userInfo-media flex-shrink-0">
                                    <Image className="userInfo-img" src={info.help_user_avatar} />
                                </div>
                                <div className="userInfo-inner">
                                    <div className="userInfo-title">接单人</div>
                                    <div className="userInfo-name">{info.help_user_nickname}</div>
                                </div>
                            </div>
                            <div className="menus">
                                {/* <div className="item-menu">
                                    <div className="icon-message"></div>
                                    <div className="item-name">私信</div>
                                </div> */}
                                {!!info.help_user_mobile && <a href={`tel:${info.help_user_mobile}`} className="item-menu">
                                    <div className="icon-phone"></div>
                                    <div className="item-name">电话</div>
                                </a>}
                            </div>
                        </div>
                        {!!info.help_content && <div className="card-inner">
                            <div className="card-time">{info.help_finish_date}</div>
                            <div className="card-text">{info.help_content}</div>
                            <div className="card-imgs">
                                {info.help_media.map((t, i) => (<div className="card-img" key={i} onClick={() => {
                                    t.is_video === 0 ? ImageViewer.show({ image: t.media }) : showVideoViewer(t.media)
                                }}>
                                    <Image
                                        className="card-img__inner"
                                        src={t.is_video === 1 ? `${t.media}?x-oss-process=video/snapshot,t_1050,f_jpg` : t.media}
                                    />
                                    {t.is_video === 1 && <div className="icon-play"></div>}
                                </div>))}
                            </div>
                        </div>}
                    </div>
                ) : (
                    <div className="people-card">
                        <div className="block-title">暂无接单人</div>
                    </div>
                )}
                <div className="cell-card">
                    <div className="item-content flex-space">
                        <div>订单编号</div>
                        <div className="flex-a-center">
                            <div>{info.order_no}</div>
                            <span className="icon-copy" onClick={() => copy(info.order_no)}></span>
                        </div>
                    </div>
                    {info.pay_at > 0 && <div className="item-content flex-space">
                        <div>支付时间</div>
                        <div>{timestampToString(info.pay_at * 1000, 2)}</div>
                    </div>}
                    {!!info.help_date && <div className="item-content flex-space">
                        <div>接单时间</div>
                        <div>{info.help_date}</div>
                    </div>}
                    {!!info.help_finish_date && <div className="item-content flex-space">
                        <div>送达时间</div>
                        <div>{info.help_finish_date}</div>
                    </div>}
                    {!!info.finish_date && <div className="item-content flex-space">
                        <div>完成时间</div>
                        <div>{info.finish_date}</div>
                    </div>}
                    {!!info.cancel_date && <div className="item-content flex-space">
                        <div>取消时间</div>
                        <div>{info.cancel_date}</div>
                    </div>}
                    <div className="item-content flex-space">
                        <div>联系生活圈管理员</div>
                        {info.admin_mobile ? <a href={`tel:${info.admin_mobile}`} className="flex-a-center">
                            <div>{formatPhoneNumber(info.admin_mobile, ' ')}</div>
                            <span className="icon-phone"></span>
                        </a> : <div>无</div>}
                    </div>
                </div>
            </div>}
            {!!info && <ZFooterBar className="flex-end">
                <div className="footer-btn" onClick={() => push(`/taskChat?id=${id}`)}>历史聊天</div>
                {info.status === 3 && mainStore.info.is_service_center === 1 && <>
                    <div className="footer-btn" onClick={onCancel}>退给用户</div>
                    <div className="footer-btn" onClick={onDelayPay}>暂停打款</div>
                    <div className="footer-btn" onClick={onFinish}>完成订单</div>
                </>}
            </ZFooterBar>}
        </div>
    )
}

export default observer(TaskDetail)