import React, { useRef, useState } from 'react'
import { DatePicker, DotLoading, Image, InfiniteScroll } from 'antd-mobile-v5'
import ZEmpty from '@/components/zEmpty'
import { getTurnoverList } from '../../../apis/account'
import { timestampToString } from '../../../utils'

const InfiniteScrollContent = ({ hasMore, isEmpty }) => {
    return (<>
        {hasMore ? (<>
            <span>加载中</span>
            <DotLoading />
        </>) : (
            !isEmpty ? <span>没有更多了</span> : <ZEmpty text="还没有订单喔～" />
        )}
    </>)
}

const List1 = (props) => {
    const [date, setDate] = useState('')
    const page = useRef(1)
    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState(true)

    async function loadMore() {
        try {
            const _page = page.current
            const { data } = await getTurnoverList({
                date: date || undefined,
                page: _page,
                limit: 10,
            })
            setList(_page === 1 ? data.data : [...list, ...data.data])
            setHasMore(data.has_more_pages)
            page.current += 1
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    const onDateChange = async() => {
        let _date = new Date()
        if (date) {
            const [y,m,d] = date.split('-')
            _date = new Date(+y, m - 1, +d)
        }
        const value = await DatePicker.prompt({
            title: '选择日期',
            defaultValue: _date
        })
        const _value = value ? timestampToString(value, 1) : ''
        if (_value != date) {
            setDate(_value)
            page.current = 1
            setList([])
            setHasMore(true)
        }
    }

    return (
        <div>
            <div className="block-title flex">
                <div className="flex-a-center" onClick={onDateChange}>
                    <span>{date || '全部'}</span>
                    <span className="icon-unfold" style={{ marginLeft: 3 }}></span>
                </div>
            </div>
            {/* <div className="income-title flex">
                <div className="item">已入账<span className="amount">¥698.59</span></div>
                <div className="item">未入账<span className="amount">¥6998.59</span></div>
            </div> */}
            <div>
                {list.map(item => (<div className="income-card" key={item.order_id}>
                    <div className="item-header flex-space">
                        <div className="item-userInfo flex-a-center">
                            <Image className="item-userInfo-img" src={item.avatar} />
                            <div className="item-userInfo-name">{item.nickname}</div>
                        </div>
                        <div className={`item-status ${item.status === 0 ? 'erro' : (item.status === 1 ? 'succ' : '')}`}>{item.status_name}</div>
                    </div>
                    <div className="item-goods flex">
                        {item.order_items.map(t => (<Image className="item-goods-img" key={t.id} src={t.product_cover} />))}
                        <div className="item-goods-after">
                            <div className="item-amount">¥{item.amount}</div>
                            <div className="item-number">共{item.total_number}件</div>
                        </div>
                    </div>
                    <div className="item-footer flex-space">
                        <div>订单编号：{item.order_no}</div>
                        <div>{item.created_at}</div>
                    </div>
                </div>))}
            </div>
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50}>
                <InfiniteScrollContent hasMore={hasMore} isEmpty={!list.length} />
            </InfiniteScroll>
        </div>
    )
}

export default List1