import React from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import './index.scss'

const BindingBank = (props) => {
    const { bank_account, bank_name } = qs.parse(useLocation().search)

    return (
        <div className="bingingBank">
            <div className="card">
                <div className="item-cell flex-space">
                    <div className="item-cell-label flex-shrink-0">卡号</div>
                    <div className="item-cell-inner flex-1">{bank_account}</div>
                </div>
                <div className="item-cell flex-space">
                    <div className="item-cell-label flex-shrink-0">银行</div>
                    <div className="item-cell-inner flex-1">{bank_name}</div>
                </div>
                {/* <div className="flex flex-end">
                    <div className="item-btn">解绑</div>
                </div> */}
            </div>
        </div>
    )
}

export default BindingBank