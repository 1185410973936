import React, { useEffect, useRef, useState } from 'react'
import './index.scss'

const ZSearchBar = ({
    value = '',
    autoFocus,
    placeholder,
    onFocus,
    onBlur,
    onClear,
    onConfirm
}) => {
    const [_value, setValue] = useState('')
    const inputRef = useRef()

    useEffect(() => {
        if (autoFocus) {
            inputRef.current && inputRef.current.focus()
        }
    }, [])

    useEffect(() => {
        setValue(value)
    }, [value])


    const _onFocus = () => {
        typeof onFocus === 'function' && onFocus()
    }

    const _onBlur = () => {
        typeof onBlur === 'function' && onBlur()
    }

    const _onClear = () => {
        setValue('')
        typeof onClear === 'function' && onClear()
    }

    const _onConfirm = () => {
        typeof onConfirm === 'function' && onConfirm(_value)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        _onConfirm()
    }

    return (
        <form className="z-search flex-1" onSubmit={onSubmit}>
            <div className="icon-search z-search-icon"></div>
            <div className="flex-1">
                <input
                    ref={inputRef}
                    type="search"
                    value={_value}
                    className="z-search-input"
                    placeholder={placeholder}
                    onChange={e => setValue(e.target.value)}
                    onFocus={_onFocus}
                    onBlur={_onBlur}
                />
            </div>
            {!!_value && <div className="icon-close z-search-close" onClick={_onClear}></div>}
            <div className="z-search-btn" onClick={_onConfirm}>搜索</div>
        </form>
    )
}

export default ZSearchBar