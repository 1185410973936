import React, { useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ActivityList1 from './components/activityList1'
import ActivityList2 from './components/activityList2'
import ActivityList3 from './components/activityList3'
import { observer } from 'mobx-react'
import mainStore from '@/store/main'
import qs from 'query-string'
import './index.scss'

const Activity = (props) => {
    const params = qs.parse(useLocation().search)
    const { info } = mainStore
    const tabList = useMemo(() => {
        const arr = [
            { id: 1, name: '我发布的' },
            { id: 2, name: '我参与的' },
        ]
        if (info.is_service_center === 1) {
            arr.push({ id: 3, name: '我处理的' })
        }
        return arr
    }, [info])
    const [type, setType] = useState(params.type ? +params.type : 1)

    return (
        <div className="activity-wrapper">
            <div className="tabs">
                {tabList.map((item) => (
                    <div
                        key={item.id}
                        className={`item ${type === item.id ? 'active' : ''}`}
                        onClick={() => setType(item.id)}
                    >{item.name}</div>
                ))}
            </div>

            {type === 1 && <ActivityList1 />}
            {type === 2 && <ActivityList2 />}
            {type === 3 && <ActivityList3 />}
        </div>
    )
}

export default observer(Activity)