import React, { useEffect, useRef, useState } from 'react'
import { Image, InfiniteScroll, Toast } from 'antd-mobile-v5'
import ZSearchBar from '../../components/zSearchBar'
import RejectModal from '../../components/rejectModal'
import './index.scss'
import { getTaskAuditList, taskExamine } from '../../apis/task'
import { showConfirm } from '../../utils'

const tabsList = [
    { id: 0, name: '全部' },
    { id: 1, name: '未处理' },
    { id: 2, name: '通过' },
    { id: 3, name: '驳回' },
]

const TaskExamine = (props) => {
    const [type, setType] = useState(0)
    const page = useRef(1)
    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const [focus, setFocus] = useState(false)
    const [keywords, setKeywords] = useState('')
    const rejectModalRef = useRef()
    const currentId = useRef(0)

    useEffect(() => {
        page.current = 1
        setList([])
        setHasMore(true)
    }, [type, keywords])

    async function loadMore() {
        try {
            const _page = page.current
            const { data } = await getTaskAuditList({
                type,
                keywords,
                page: _page,
                limit: 10,
            })
            setList(_page === 1 ? data.data : [...list, ...data.data])
            setHasMore(data.has_more_pages)
            page.current += 1
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    const setStatus = async(status, msg) => {
        const { data } = await taskExamine({
            user_id: currentId.current,
            status,
            examine_msg: msg
        })
        Toast.show('操作成功')
        page.current = 1
        setList([])
        setHasMore(true)
    }

    const onOk = async() => {
        const res = await showConfirm({
            title: '提示',
            content: '是否确认通过该用户？'
        })
        if (!res) return
        setStatus(1)
    }

    return (
        <div className="taskExamine">
            <div className="header flex-space">
                {!focus ? <>
                    <div className="tabs flex">
                        {tabsList.map(item => (
                            <div
                                key={item.id}
                                className={`item ${type === item.id ? 'active' : ''}`}
                                onClick={() => setType(item.id)}
                            >{item.name}</div>
                        ))}
                    </div>
                    <div className="search-box" onClick={() => setFocus(true)}>
                        <span className="icon-search"></span>
                        <span>搜索</span>
                    </div>
                </> : <ZSearchBar
                    value={keywords}
                    autoFocus
                    placeholder="请输入内容"
                    onConfirm={(v) => {
                        setKeywords(v)
                        setFocus(false)
                    }}
                />}
            </div>
            <div className="content">
                {list.map(item => (<div className="card" key={item.id}>
                    <div className="card-header flex">
                        <div className="item-media flex-shrink-0">
                            <Image className="item-img" src={''} />
                        </div>
                        <div className="item-inner flex-1">
                            <div className="item-title">{item.username}</div>
                            <div className="item-subtl">申请时间：{item.application_time}</div>
                        </div>
                    </div>
                    <div className="card-inner">
                        <div className="item-cells flex">
                            <div className="item-cell flex">
                                <div className="item-cell-label flex-shrink-0">真实姓名：</div>
                                <div className="item-cell-inner flex-1">{item.truename || '无'}</div>
                            </div>
                            <div className="item-cell flex">
                                <div className="item-cell-label flex-shrink-0">性别：</div>
                                <div className="item-cell-inner flex-1">{item.gender_text}</div>
                            </div>
                        </div>
                        <div className="item-cells flex">
                            <div className="item-cell flex">
                                <div className="item-cell-label flex-shrink-0">所在商圈：</div>
                                <div className="item-cell-inner flex-1 ell1">{item.business_district_name}</div>
                            </div>
                            <div className="item-cell flex">
                                <div className="item-cell-label flex-shrink-0">手机号：</div>
                                <div className="item-cell-inner flex-1">{item.mobile || '无'}</div>
                            </div>
                        </div>
                        <div className="item-pics">
                            <Image className="item-pic" src={item.id_card_front} fit="contain" />
                            <Image className="item-pic" src={item.id_card_back} fit="contain" />
                            {!!item.life_photos && <Image className="item-pic" src={item.life_photos} fit="contain" />}
                        </div>
                    </div>
                    {item.status === 0 && <div className="card-footer flex flex-end">
                        <div className="item-btn" onClick={() => {
                            currentId.current = item.id
                            rejectModalRef.current && rejectModalRef.current.show()
                        }}>驳回</div>
                        <div className="item-btn main" onClick={() => {
                            currentId.current = item.id
                            onOk()
                        }}>通过</div>
                    </div>}
                    {item.status === 1 && <div className="card-footer">
                        <div className="item-status succ flex-a-center">
                            <span className="icon-succ"></span>
                            <span>已通过</span>
                        </div>
                    </div>}
                    {item.status === 2 && <div className="card-footer">
                        <div className="item-status erro flex-a-center">
                            <span className="icon-fail"></span>
                            <span>驳回：{item.audit_msg}</span>
                        </div>
                    </div>}
                </div>))}
            </div>
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50} />
            <RejectModal ref={rejectModalRef} onConfirm={(v, hide) => {
                setStatus(2, v).finally(() => {
                    hide()
                })
            }} />
        </div>
    )
}

export default TaskExamine