import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CenterPopup } from 'antd-mobile-v5'
import DatasView from './components/datasView'
import KefuModal from '../../components/kefuModal'
import { observer } from 'mobx-react'
import mainStore from '@/store/main'
import './index.scss'
import { getOrderData } from '@/apis/home'

const ewmIcon = require('@/assets/images/ewm.png')

const tabsList = [
    { id: 1, name: '外卖' },
    { id: 2, name: '外卖自取' },
    { id: 3, name: '物流' },
    { id: 4, name: '扫码点餐' },
]
const orderMap = {
    1: 'take_out_data',
    2: 'take_out_self_pickup_data',
    3: 'logistics_data',
    4: 'scan_code_to_order_data'
}

const Home = () => {
    const { info } = mainStore
    const { push } = useHistory()
    const [type, setType] = useState(1)
    const [datas, setDatas] = useState({
        take_out_data: {},
        take_out_self_pickup_data: {},
        logistics_data: {},
        scan_code_to_order_data: {}
    })
    const [visible, setVisible] = useState(false)
    const kefuModalRef = useRef()
    const orders = useMemo(() => {
        const _data = datas[orderMap[type]]
        switch (type) {
        case 1:
        case 3:
            return [
                { name: '今日订单', value: _data.today_order_num },
                { name: '全部订单', value: _data.all_order_num },
                { name: '未支付', value: _data.no_pay_order_num },
                { name: '待发货', value: type === 1 ? _data.pend_shipment_num : _data.pending_shipment_num },
                { name: '配送中', value: _data.in_progress_num },
                { name: '配送异常', value: _data.delivery_exception_num },
                { name: '已完成', value: _data.success_num },
                { name: '退款中', value: _data.refunding_num },
                { name: '已退款', value: _data.refunded_num },
                { name: '已关闭', value: _data.close_num },
            ]
        case 2:
            return [
                { name: '今日订单', value: _data.today_order_num },
                { name: '全部订单', value: _data.all_order_num },
                { name: '未支付', value: _data.no_pay_order_num },
                { name: '制作中', value: _data.in_production_num },
                { name: '待核销', value: _data.pending_verification_num },
                { name: '已完成', value: _data.success_num },
                { name: '退款中', value: _data.refunding_num },
                { name: '已退款', value: _data.refunded_num },
                { name: '已关闭', value: _data.close_num },
                { name: '' }
            ]
        case 4:
            return [
                { name: '今日订单', value: _data.today_order_num },
                { name: '全部订单', value: _data.all_order_num },
                { name: '未支付', value: _data.no_pay_order_num },
                { name: '制作中', value: _data.in_production_num },
                { name: '待取餐', value: _data.wait_pick_up_meals },
                { name: '已完成', value: _data.success_num },
                { name: '退款中', value: _data.refunding_num },
                { name: '已退款', value: _data.refunded_num },
                { name: '已关闭', value: _data.close_num },
                { name: '' }
            ]
        default:
            return []
        }
        
    }, [datas])

    const menus = useMemo(() => {
        return [
            {
                id: 0,
                icon: 'icon-publish',
                name: '发布商品',
                onClick: () => push('/addGoods')
            },
            {
                id: 1,
                icon: 'icon-goods',
                name: '商品管理',
                onClick: () => push('/goodsManage')
            },
            {
                id: 2,
                icon: 'icon-refund',
                name: '售后管理',
                onClick: () => push('/afterSalesManage')
            },
            {
                id: 3,
                icon: 'icon-activity',
                name: '活动管理',
                onClick: () => push('/activityManage')
            },
            {
                id: 4,
                icon: 'icon-coupon',
                name: '数字券管理',
                onClick: () => push('/couponManage')
            },
            {
                id: 5,
                icon: 'icon-task',
                name: '任务管理',
                onClick: () => push('/taskManage')
            },
            {
                id: 6,
                icon: 'icon-category',
                name: '类目管理',
                onClick: () => push('/category')
            },
            // {
            //     id: 6,
            //     icon: 'icon-store',
            //     name: '店铺管理',
            // }
        ]
    }, [])

    useEffect(() => {
        mainStore.getInfo()
    }, [])
    useEffect(() => {
        getData()
    }, [type])


    // 获取订单数据
    const getData = async() => {
        try {
            const { data } = await getOrderData({ type: type })
            setDatas(data)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="storeManage">
            <div className="header flex flex-end">
                {info.short_name ? <div className="flex flex-1">
                    <div className="item-media flex-shrink-0" onClick={() => push('/storeInfo')}>
                        <img className="item-img" src={info.cover} />
                        <div className="item-edit">
                            <span className="icon-edit"></span>
                        </div>
                    </div>
                    <div className="item-inner flex-1">
                        <div className="item-title ell1">{info.short_name}</div>
                        {/* <div className="item-subtl">当前账号:<span>主账号</span></div> */}
                        <div className="item-subtl">开店时间:<span>{info.created_at}</span></div>
                    </div>
                </div> : <div className="flex-1"></div>}
                <div className="item-ewm" onClick={() => {
                    if(!info.qr_code) return
                    setVisible(true)
                }}>
                    <img className="item-ewm-img" src={ewmIcon} alt="" />
                    <span className="item-ewm-name">店铺码</span>
                </div>
            </div>
            <div className="card">
                <div className="item flex-center" onClick={() => push('/accountFunds')}>
                    <span className="icon-account"></span>
                    <span className="item-name">账户资金</span>
                </div>
                <div className="item flex-center" onClick={() => {
                    kefuModalRef.current && kefuModalRef.current.show()
                }}>
                    <span className="icon-kefu"></span>
                    <span className="item-name">官方客服</span>
                </div>
                <div className="item flex-center" onClick={() => push('/setup')}>
                    <span className="icon-setup"></span>
                    <span className="item-name">设置</span>
                </div>
            </div>
            <div className="order-card">
                <div className="item-header">
                    {tabsList.map(t => (
                        <div
                            key={t.id}
                            className={`item-tab ${type === t.id ? 'active' : ''}`}
                            onClick={() => setType(t.id)}
                        >{t.name}</div>
                    ))}
                </div>
                <div className="item-content">
                    <div className="item-cells flex flex-jsb flex-wrap">
                        {orders.map((item, index) => (<div className="item-cell" key={index}>
                            {!!item.name && <>
                                <div className="item-cell__value">{item.value || 0}</div>
                                <div className="item-cell__label">{item.name}</div>
                            </>}
                        </div>))}
                    </div>
                </div>
            </div>
            <div className="menu-card flex flex-wrap">
                {menus.map(t => (<div className="item-menu" key={t.id} onClick={t.onClick}>
                    <div className={t.icon}></div>
                    <div className="item-menu__name">{t.name}</div>
                </div>))}
            </div>
            <DatasView />
            <CenterPopup
                visible={visible}
                bodyClassName="ewm-popup"
                onMaskClick={() => setVisible(false)}
                style={{
                    '--min-width': 'auto'
                }}
            >
                <a id="qrcode" className="ewm-popup__img" href={info.qr_code} download="qrcode.png">
                    <img src={info.qr_code} alt="" />
                </a>
                <div className="ewm-popup__title">下载二维码开启顾客线下扫码自助下单</div>
                <div className="ewm-popup__btn" onClick={() => {
                    const a = document.getElementById('qrcode')
                    a.click()
                }}>
                    <span className="icon-download"></span>
                    <span>下载二维码</span>
                </div>
                <div className="ewm-popup__close" onClick={() => setVisible(false)}></div>
            </CenterPopup>
            <KefuModal ref={kefuModalRef} />
        </div>
    )
}

export default observer(Home)