import React, { useEffect } from 'react'
import { Image } from 'antd-mobile-v5'
import { observer } from 'mobx-react'
import mainStore from '@/store/main'
import './index.scss'

const BankInfo = () => {
    const { info } = mainStore

    useEffect(() => {
        if (!info.id) {
            mainStore.getInfo()
        }
    }, [])

    return (
        <div className="infos-wrapper">
            <Image className="infos-img" src={info.bank_card_image} fit="contain" />
            <div className="infos-inner">
                <div className="infos-title">银行卡</div>
                <div className="infos-cell flex">
                    <div className="item-label">银行卡号</div>
                    <div className="item-title flex-1">{info.bank_account}</div>
                </div>
                <div className="infos-cell flex">
                    <div className="item-label">银行名称</div>
                    <div className="item-title flex-1">{info.bank_name}</div>
                </div>
                <div className="infos-cell flex">
                    <div className="item-label">支行名称</div>
                    <div className="item-title flex-1">{info.bank_branch}</div>
                </div>
            </div>
        </div>
    )
}

export default observer(BankInfo)