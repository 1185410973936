import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { InfiniteScroll, Toast } from 'antd-mobile-v5'
import ZSearchBar from '../../components/zSearchBar'
import ZFooterBar from '../../components/zFooterBar'
import './index.scss'
import { couponDel, couponEditTime, getNumeralCouponList } from '../../apis/coupon'
import { showConfirm } from '../../utils'

const statusList = [
    { id: 1, name: '未开始' },
    { id: 2, name: '进行中' },
    { id: 3, name: '已结束' },
]

const typeList = [
    { id: 1, name: '满减券' },
    { id: 2, name: '折扣券' },
]

const CouponManage = (props) => {
    const { push } = useHistory()
    const [status, setStatus] = useState(1)
    const [type, setType] = useState(1)
    const page = useRef(1)
    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const [focus, setFocus] = useState(false)
    const [keywords, setKeywords] = useState('')

    useEffect(() => {
        page.current = 1
        setList([])
        setHasMore(true)
    }, [type, status, keywords])

    async function loadMore() {
        try {
            const _page = page.current
            const { data } = await getNumeralCouponList({
                type,
                status,
                keywords,
                page: _page,
                limit: 10,
            })
            setList(_page === 1 ? data.data : [...list, ...data.data])
            setHasMore(data.has_more_pages)
            page.current += 1
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    const onEditTime = async (id, type) => {
        const res = await showConfirm({
            title: '提示',
            content: `是否确认${type === 1 ? '提前开始' : '提前结束'}？`
        })
        if (!res) return
        try {
            await couponEditTime({
                id: id,
                type: type
            })
            Toast.show('操作成功')
            page.current = 1
            setList([])
            setHasMore(true)
        } catch (e) {
            console.log(e)
        }
    }

    const onDel = async (id) => {
        const res = await showConfirm({
            title: '提示',
            content: '是否确认删除此数字券？'
        })
        if (!res) return
        try {
            await couponDel({ id })
            Toast.show('删除成功')
            page.current = 1
            setList([])
            setHasMore(true)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="couponManage">
            <div className="header flex-space">
                {!focus ? <>
                    <div className="tabs flex">
                        {statusList.map(item => (
                            <div
                                key={item.id}
                                className={`item ${status === item.id ? 'active' : ''}`}
                                onClick={() => setStatus(item.id)}
                            >{item.name}</div>
                        ))}
                    </div>
                    <div className="search-box" onClick={() => setFocus(true)}>
                        <span className="icon-search"></span>
                        <span>搜索</span>
                    </div>
                </> : <ZSearchBar
                    value={keywords}
                    autoFocus
                    placeholder="请输入内容"
                    onConfirm={(v) => {
                        setKeywords(v)
                        setFocus(false)
                    }}
                />}
            </div>
            <div className="tags">
                {typeList.map(item => (
                    <div
                        key={item.id}
                        className={`item-tag ${type === item.id ? 'active' : ''}`}
                        onClick={() => setType(item.id)}
                    >{item.name}</div>
                ))}
            </div>
            <div className="coupon-list">
                {list.map(item => (<div className="item" key={item.id}>
                    <div className="item-content flex-a-center">
                        <div className="item-media flex-shrink-0">
                            <div className="item-amount">
                                {item.type != 2 && <div className="unit">¥</div>}
                                <div className="amount">{+item.money}</div>
                                {item.type == 2 && <div className="unit">折</div>}
                            </div>
                            <div className="item-text">{item.discount_desc}</div>
                        </div>
                        <div className="item-inner flex-1">
                            <div className="item-title ell1">{item.name}</div>
                            {item.status === 1 && <div className="item-subtl">{item.start_time}开始</div>}
                            <div className="item-subtl">{item.end_time}结束</div>
                        </div>
                    </div>
                    <div className="item-footer">
                        {item.status === 1 && <>
                            <div className="item-btn" onClick={() => onDel(item.id)}>删除</div>
                            <div className="item-btn" onClick={() => push(`/editCoupon?id=${item.id}`)}>编辑</div>
                            <div className="item-btn main" onClick={() => onEditTime(item.id, 1)}>提前开始</div>
                        </>}
                        {item.status === 2 && <>
                            <div className="item-btn" onClick={() => onEditTime(item.id, 2)}>提前结束</div>
                        </>}
                        {(item.status === 2 || item.status === 3) && <div className="item-btn main" onClick={() => push(`/seeCoupon?id=${item.id}`)}>查看</div>}
                    </div>
                </div>))}
            </div>
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50} />
            <ZFooterBar>
                <div className="footer-btn flex-1" onClick={() => push('/addCoupon')}><span className="icon-add"></span>新增数字券</div>
            </ZFooterBar>
        </div>
    )
}

export default CouponManage