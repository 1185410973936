import gql from 'graphql-tag'

// 获取商店里商品信息
export const Get_Store_Categorys = gql`
  query ($limit: Int, $page: Int) {
    ProductCategories(limit: $limit, page: $page) {
      data {
        id
        name
        status
        sort_order
        created_at
        updated_at
      }
      total
      per_page
      current_page
      last_page
      has_more_pages
    }
  }
`;

// 增加类目
export const Add_Lei_Mu_Mutation = gql`
  mutation ($name: String!, $sort_order: Int!, $status: Int!) {
    addProductCategory(name: $name, sort_order: $sort_order, status: $status)
  }
`;

// 删除类目
export const Delete_Lei_Mu_Mutation = gql`
  mutation ($product_category_id: Int!) {
    delProductCategory(product_category_id: $product_category_id)
  }
`;

// 修改类目
export const Xiu_Gai_Lei_Mu_Mutation = gql`
  mutation (
    $product_category_id: Int!
    $name: String
    $sort_order: Int!
    $status: Int!
  ) {
    updateProductCategory(
      product_category_id: $product_category_id
      name: $name
      sort_order: $sort_order
      status: $status
    ) {
      id
      name
      status
      sort_order
      created_at
      updated_at
    }
  }
`;