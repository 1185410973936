import React, { useImperativeHandle, useRef, useState } from 'react'
import { Image, InfiniteScroll, SearchBar, Toast } from 'antd-mobile-v5'
import ZModal from '../zModal'
import './index.scss'
import { getCouponUserList } from '../../apis/coupon'

const UserModal = React.forwardRef(({ title, type = 'multiple', visible, onClose, onConfirm }, ref) => {
    const page = useRef(1)
    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [checkedList, setCheckedList] = useState([])
    const [keywords, setKeywords] = useState('')

    const loadMore = async() => {
        try {
            const _page = page.current
            const { data } = await getCouponUserList({
                page: _page,
                limit: 10,
                keywords
            })
            setList(_page === 1 ? data.data : [...list, ...data.data])
            setHasMore(data.has_more_pages)
            page.current += 1
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    const onItemChange = (item) => {
        if (type === 'multiple') {
            const index = checkedList.findIndex(t => t.id === item.id)
            if (index === -1) {
                setCheckedList([...checkedList, item])
            } else {
                const _list = [...checkedList]
                _list.splice(index, 1)
                setCheckedList(_list)
            }
        } else {
            setCheckedList([item])
        }
    }

    const onSubmit = () => {
        if (!checkedList.length) {
            Toast.show('请选择用户')
            return
        }
        onConfirm && onConfirm(checkedList)
        onClose && onClose()
    }

    const onSearch = (v) => {
        page.current = 1
        setKeywords(v)
        setList([])
        setHasMore(true)
    }

    useImperativeHandle(ref, () => {
        return {
            reset: (v) => {
                setCheckedList(v || [])
            }
        }
    })

    return (
        <ZModal
            title={title}
            className="user-modal"
            visible={visible}
            onClose={onClose}
            showButton
            onConfirm={onSubmit}
        >
            <SearchBar placeholder='输入用户昵称或ID搜索' onClear={() => onSearch('')} onSearch={v => onSearch(v)} />
            <div className="user-modal-inner">
                <div className="user-modal-list">
                    {list.map((item, index) => (<div className="item-content flex-a-center" key={item.id}>
                        <div className="item-media flex-shrink-0">
                            <Image className="item-img" src={item.avatar} />
                        </div>
                        <div className="item-inner flex-1">
                            <div className="item-title ell1">{item.nickname}</div>
                            <div className="item-subtl">ID：{item.id}</div>
                        </div>
                        <div className={`icon-checkbox item-checkbox ${checkedList.some(t => t.id === item.id) ? 'active' : ''}`} onClick={() => onItemChange(item, index)}></div>
                    </div>))}
                </div>
                <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50} />
            </div>
        </ZModal>
    )
})

export default UserModal