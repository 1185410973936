import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Swiper, Toast } from 'antd-mobile-v5'
import { RightOutline } from 'antd-mobile-icons'
import List1 from './components/list1'
import List2 from './components/list2'
import KefuModal from '../../components/kefuModal'
import './index.scss'
import { addWithdraw, getTurnoverBalance } from '../../apis/account'

const tabList = [
    { id: 0, name: '货款余额' },
    // { id: 1, name: '营销账户' },
    // { id: 2, name: '店铺保证金' },
    // { id: 3, name: '活动保证金' }
]

const AccountFunds = (props) => {
    const { push } = useHistory()
    const [type, setType] = useState(0)
    const [status, setStatus] = useState(0)
    const [account, setAccount] = useState({
        total_turnover: 0,
        turnover_balance: 0,
        wait_turnover_balance: 0,
        bank_account: '',
        bank_name: '',
    })
    const kefuModalRef = useRef()

    useEffect(() => {
        getData()
    }, [])

    const getData = async() => {
        try {
            const { data } = await getTurnoverBalance()
            setAccount(data)
        } catch (e) {
            console.log(e)
        }
    }

    const onWithdraw = async() => {
        if (+account.turnover_balance === 0) {
            Toast.show('暂无提现金额')
            return
        }
        try {
            const { data } = await addWithdraw({
                amount: account.turnover_balance,
                amount_type: 1,
                withdraw_type: 3
            })
            if (data) {
                Toast.show('提交成功')
                getData()
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="accountFunds flex-column">
            <div className="tabs">
                {tabList.map((item) => (
                    <div
                        key={item.id}
                        className={`item ${type === item.id ? 'active' : ''}`}
                        onClick={() => setType(item.id)}
                    >{item.name}</div>
                ))}
            </div>
            <Swiper defaultIndex={0} slideSize={92} trackOffset={4} stuckAtBoundary={false} indicator={false}>
                <Swiper.Item>
                    <div className="account-card card1" style={{ marginRight: 0 }}>
                        <div className="item-total flex-a-center">累计货款<span className="total">¥{account.total_turnover}</span></div>
                        <div className="item-title flex-a-center">
                            <span className="icon-account1 item-icon"></span>
                            <span>货款余额</span>
                        </div>
                        <div className="flex align-end">
                            <div className="item-amount">¥{account.turnover_balance}</div>
                            <div className="item-subtl">待入账 ¥{account.wait_turnover_balance}</div>
                        </div>
                        <div className="item-btn" onClick={onWithdraw}>提现</div>
                    </div>
                </Swiper.Item>
            </Swiper>
            <div className="bank-card flex-space" onClick={() => push(`/bindingBank?bank_account=${account.bank_account}&bank_name=${account.bank_name}`)}>
                <div className="item-title">银行卡管理</div>
                <div className="item-text flex-a-center">已绑定<RightOutline /></div>
            </div>
            <div className="btns">
                <div className={`item-btn ${status === 0 ? 'active' : ''}`} onClick={() => setStatus(0)}>账单明细</div>
                <div className={`item-btn ${status === 1 ? 'active' : ''}`} onClick={() => setStatus(1)}>提现记录</div>
            </div>
            <div className="content flex-1">
                {status === 0 && <List1 />}
                {status === 1 && <List2 />}
            </div>
            <div className="kefu-btn" onClick={() => {
                kefuModalRef.current && kefuModalRef.current.show()
            }}>
                <span className="icon-kefu1"></span>
                <span>咨询</span>
            </div>
            <KefuModal ref={kefuModalRef} />
        </div>
    )
}

export default AccountFunds