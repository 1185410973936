import React, { useRef, useState } from 'react'
import { Image, InfiniteScroll } from 'antd-mobile-v5'
import { myJoinActivity } from '../../../apis/activity'

const ActivityList2 = () => {
    const page = useRef(1)
    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState(true)

    async function loadMore() {
        try {
            const _page = page.current
            const { data } = await myJoinActivity({
                page: _page,
                limit: 10,
            })
            setList(_page === 1 ? data.data : [...list, ...data.data])
            setHasMore(data.has_more_pages)
            page.current += 1
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    return (
        <div>
            <div className="content-card" style={{ paddingTop: 0 }}>
                <div className="activity-list">
                    {list.map(item => (<div className="item-content" key={item.id}>
                        <div className="flex">
                            <div className="item-media flex-shrink-0">
                                <Image className="item-img" src={item.cover} />
                                {item.heat > 0 && <div className="item-hot flex-a-center">
                                    <div className="icon-hot"></div>
                                    <div>{item.heat}</div>
                                </div>}
                            </div>
                            <div className="item-inner flex-1">
                                <div className="flex-space">
                                    <div className="item-title ell1">{item.title}</div>
                                    <div className={`item-status ${item.status === 4 ? 'succ' : ''}`}>{item.status_html}</div>
                                </div>
                                <div className="item-subtl flex-a-center"><span className="icon-time"></span>{item.end_time}结束</div>
                                {item.involved_num > 0 && <div className="item-user flex-a-center">
                                    <div className="item-user-imgs">
                                        {item.involved_num > 3 && <div className="item-user-more">
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                        </div>}
                                        {item.involved_user_list.map(t => (<img key={t.id} className="item-user-img" src={t.avatar} />))}
                                    </div>
                                    <div className="item-user-text">{item.involved_num}人已参加</div>
                                </div>}
                                <div className="item-text">转发{item.share_num} · 裂变{item.finds_num} · 观看{item.fans_num}</div>
                            </div>
                        </div>
                    </div>))}
                </div>
                <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50} />
            </div>
        </div>
    )
}

export default ActivityList2