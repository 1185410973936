import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Image, Toast } from 'antd-mobile-v5'
import { RightOutline } from 'antd-mobile-icons'
import { observer } from 'mobx-react'
import mainStore from '@/store/main'
import { copy } from '@/utils'
import app from '@/app.json'
import { uploadImages } from '@/apis/common'
import './index.scss'
import { isPhone } from '../../utils/regexp'

const StoreInfo = () => {
    const { push } = useHistory()
    const { info } = mainStore
    const [value, setValue] = useState('')
    const [focus, setFocus] = useState(false)
    const [hotLine, setHotLine] = useState('')

    useEffect(() => {
        if (!info.id) {
            mainStore.getInfo()
        }
    }, [])

    useEffect(() => {
        if (info.id) {
            setHotLine(info.hot_line)
        }
    }, [info])

    const updateAuthor = async(e) => {
        console.log(e)
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('file', file)
        try {
            const { data } = await uploadImages(formData)
            const url = app.oss_host + data
            mainStore.updateInfo({
                type: 2,
                cover: url
            })
        } catch (e) {
            console.log(e)
        }
    }

    const updateName = () => {
        if (!value) {
            Toast.show('店铺名称不能为空')
            return
        }
        mainStore.updateInfo({
            type: 1,
            short_name: value
        }).then(() => {
            setFocus(false)
        })
    }

    const updateHotLine = () => {
        if (!info.id) return
        if (!isPhone(hotLine)) {
            Toast.show('请输入正确的电话号码')
            setHotLine(info.hot_line)
            return
        }
        mainStore.updateInfo({
            type: 3,
            hot_line: hotLine
        })
    }

    return (
        <div className="storeInfo">
            <div className="header">
                <div className="header-info flex align-center">
                    <label className="item-media flex-shrink-0">
                        <Image src={info.cover} className="item-img" />
                        <div className="item-media-text">修改</div>
                        <input type="file" accept="image/*" style={{ display: 'none' }} onChange={updateAuthor} />
                    </label>
                    <div className="item-inner flex-1">
                        {focus ? <div>
                            <input
                                value={value}
                                type="text"
                                className="item-input"
                                autoFocus
                                onChange={(e) => setValue(e.target.value)}
                                onBlur={updateName}
                            />
                        </div> : <div className="flex-a-center">
                            <div className="item-title ell1">{info.short_name || '-'}</div>
                            <div className="item-edit flex-center" onClick={() => {
                                if (!info.id) return
                                setValue(info.short_name || '')
                                setFocus(true)
                            }}>
                                <span>修改</span>
                                <span className="icon-edit1"></span>
                            </div>
                        </div>}
                        <div className="item-subtl">当前账号: 主账号</div>
                    </div>
                </div>
                <div className="header-cells">
                    <div className="header-cell">开店时间：{info.created_at || '-'}</div>
                    {info.id
                        ? <div className="header-cell">店铺ID：{info.id}<span className="icon-copy" style={{ marginLeft: 3 }} onClick={() => copy(info.id)}></span></div>
                        : <div className="header-cell">店铺ID：-</div>}
                </div>
                <div className="header-cells">
                    <div className="header-cell">店铺类型：{info.type_name || '-'}</div>
                    <div className="header-cell">商家类目：{info.store_category_name || '-'}</div>
                </div>
            </div>
            <div className="cells">
                <div className="item-cell flex-a-center">
                    <div className="icon-phone item-media"></div>
                    <div className="item-inner flex-space flex-1">
                        <div className="item-title">客服手机号</div>
                        <div className="flex-1">
                            {/* <div className="item-title" style={{ textAlign: 'right' }}>{info.hot_line}</div> */}
                            <input
                                className="item-input"
                                type="tel"
                                value={hotLine}
                                onChange={e => setHotLine(e.target.value)}
                                onBlur={updateHotLine}
                            />
                        </div>
                        <RightOutline />
                    </div>
                </div>
                <div className="item-cell flex-a-center">
                    <div className="icon-location item-media"></div>
                    <div className="item-inner flex-space flex-1">
                        <div className="item-title flex-shrink-0">店铺地址</div>
                        <div className="item-subtl">{info.id ? info.province + info.city + info.area + info.address : ''}</div>
                    </div>
                </div>
            </div>
            <div className="cells">
                <div className="item-cell flex-a-center">
                    <div className="icon-alipay item-media"></div>
                    <div className="item-inner flex-space flex-1">
                        <div className="item-title">支付宝账号</div>
                        <div className="item-subtl">{info.ali_account}</div>
                    </div>
                </div>
                <div className="item-cell flex-a-center">
                    <div className="icon-user item-media"></div>
                    <div className="item-inner flex-space flex-1">
                        <div className="item-title">推荐人手机号</div>
                        <div className="item-subtl">{info.inviter_mobile}</div>
                    </div>
                </div>
                <div className="item-cell flex-a-center" onClick={() => {
                    if (!info.id) return
                    push('/idcardInfo')
                }}>
                    <div className="icon-idcard item-media"></div>
                    <div className="item-inner flex-space flex-1">
                        <div className="item-title">身份信息</div>
                        <RightOutline />
                    </div>
                </div>
                <div className="item-cell flex-a-center" onClick={() => {
                    if (!info.id) return
                    push('/bankInfo')
                }}>
                    <div className="icon-bank item-media"></div>
                    <div className="item-inner flex-space flex-1">
                        <div className="item-title">银行卡</div>
                        <RightOutline />
                    </div>
                </div>
                <div className="item-cell flex-a-center" onClick={() => {
                    if (!info.id) return
                    push('/licenseInfo')
                }}>
                    <div className="icon-license item-media"></div>
                    <div className="item-inner flex-space flex-1">
                        <div className="item-title">营业执照</div>
                        <RightOutline />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(StoreInfo)