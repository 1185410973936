import React, { useEffect, useRef } from 'react'
import * as echarts from 'echarts'

const Echart = ({ options = {} }) => {
    const chartRef = useRef()
    const instance = useRef()

    useEffect(() => {
        if (chartRef.current) {
            instance.current && instance.current.setOption(options, { notMerge: true })
        }
    }, [options])

    useEffect(() => {
        if (chartRef.current) {
            instance.current = echarts.init(chartRef.current)
            instance.current.setOption(options)
        }
        function onResize() {
            instance.current && instance.current.resize()
        }
        window.addEventListener('resize', onResize)
        return () => {
            window.removeEventListener('resize', onResize)
            instance.current && instance.current.dispose()
        }
    }, [])


    return (
        <div ref={chartRef} className="charts" style={{ width: '100%', height: '100%' }}></div>
    )
}

export default Echart