import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Image, InfiniteScroll } from 'antd-mobile-v5'
import ZSearchBar from '../../components/zSearchBar'
import './index.scss'
import { getTaskAuditNum, getTaskList } from '../../apis/task'

const TaskManage = (props) => {
    const { push } = useHistory()
    const page = useRef(1)
    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const [keywords, setKeywords] = useState('')
    const [num, setNum] = useState(0)

    useEffect(() => {
        getNum()
    }, [])

    useEffect(() => {
        page.current = 1
        setList([])
        setHasMore(true)
    }, [keywords])

    const getNum = async() => {
        try {
            const { data } = await getTaskAuditNum()
            setNum(data.notice)
        } catch (e) {
            console.log(e)
        }
    }

    async function loadMore() {
        try {
            const _page = page.current
            const { data } = await getTaskList({
                order_no: keywords,
                page: _page,
                limit: 10,
            })
            setList(_page === 1 ? data.data : [...list, ...data.data])
            setHasMore(data.has_more_pages)
            page.current += 1
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    return (
        <div className="taskManage">
            <div className="header flex-space">
                <div className="header-examine flex-column align-center" onClick={() => push('/taskExamine')}>
                    {num > 0 && <span className="num">{num}</span>}
                    <span className="icon-examine"></span>
                    <span className="text">审核</span>
                </div>
                <ZSearchBar placeholder="请输入任务订单号" onConfirm={v => setKeywords(v)} />
            </div>
            <div className="content">
                {list.map(item => (<div className="task-card" key={item.id} onClick={() => push(`/taskDetail?id=${item.id}`)}>
                    <div className="card-header flex-space">
                        <div className="userInfo flex">
                            <div className="userInfo-media flex-shrink-0">
                                <Image className="userInfo-img" src={item.avatar} />
                            </div>
                            <div className="userInfo-inner">
                                <div className="userInfo-title">{item.nickname}</div>
                                <div className="userInfo-text">{item.date_diff}</div>
                            </div>
                        </div>
                        <div className="flex-a-center">
                            <div className="amount">￥{item.price}</div>
                            <div className={`status-btn flex-center ${[1,2].includes(item.status) ? 'succ' : item.status === 0 ? 'erro' : ''}`}>
                                {item.status === 1 && <span className="dot"></span>}
                                <span>{item.status_html}</span>
                            </div>
                        </div>
                    </div>
                    <div className="card-content">
                        <div className="card-title">{item.content}</div>
                        <div className="cells">
                            <div className="cell flex-a-center">
                                <div className="item-media flex-shrink-0">取</div>
                                <div className="item-inner flex-1">
                                    <div className="item-title ell1">{item.start_position_address}</div>
                                </div>
                            </div>
                            <div className="cell active flex-a-center">
                                <div className="item-media flex-shrink-0">收</div>
                                <div className="item-inner flex-1">
                                    <div className="item-title ell1">{item.end_position_address}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>))}
            </div>
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50} />
        </div>
    )
}

export default TaskManage