import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Image, InfiniteScroll, Toast } from 'antd-mobile-v5'
import ZModal from '../../components/zModal'
import RejectModal from '../../components/rejectModal'
import ZSearchBar from '../../components/zSearchBar'
import './index.scss'
import { getRefundList, refundAudit } from '../../apis/order'

const tabsList = [
    { id: 0, name: '全部' },
    { id: 1, name: '仅退款' },
    { id: 2, name: '退货退款' },
]

const AfterSalesManage = (props) => {
    const { push } = useHistory()
    const [type, setType] = useState(0)
    const [status, setStatus] = useState(1)
    const page = useRef(1)
    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const [focus, setFocus] = useState(false)
    const [keywords, setKeywords] = useState('')
    const [nums, setNums] = useState({
        num1: 0,
        num2: 0
    })

    const [amount, setAmount] = useState('')
    const [remark, setRemark] = useState('')
    const [visible, setVisible] = useState(false)
    const rejectModalRef = useRef()
    const [currentData, setCurrentData] = useState({
        id: 0,
        amount: 0,
    })
    const statusList = useMemo(() => ([
        { id: 1, name: `待处理(${nums.num1})` },
        { id: 2, name: `已处理(${nums.num2})` },
    ]), [nums])

    useEffect(() => {
        page.current = 1
        setList([])
        setHasMore(true)
    }, [type, status, keywords])

    async function loadMore() {
        try {
            const _page = page.current
            const { data } = await getRefundList({
                type,
                status,
                order_no: keywords,
                page: _page,
                limit: 10,
            })
            setNums({
                num1: data.new_order_num,
                num2: data.finish_order_num
            })
            setList(_page === 1 ? data.data : [...list, ...data.data])
            setHasMore(data.has_more_pages)
            page.current += 1
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    const onBeforeReject = (item) => {
        setCurrentData({
            id: item.id,
            amount: item.amount,
        })
        rejectModalRef.current && rejectModalRef.current.show()
    }
    const onReject = async (v) => {
        try {
            await refundAudit({
                refund_id: currentData.id,
                status: 3,
                reason: v
            })
            Toast.show('操作成功')
            page.current = 1
            setList([])
            setHasMore(true)
        } catch (e) {
            console.log(e)
        }
    }

    const onBeforeOk = (item) => {
        setCurrentData({
            id: item.id,
            amount: item.amount,
        })
        setAmount('')
        setRemark('')
        setVisible(true)
    }
    const onOk = async () => {
        if (+amount < 0) {
            Toast.show('减扣金额不能小于0')
            return
        } else if (+amount > currentData.amount) {
            Toast.show('减扣金额不能大于退款金额')
            return
        }
        try {
            await refundAudit({
                refund_id: currentData.id,
                status: 2,
                amount: amount || 0,
                reason: remark
            })
            Toast.show('操作成功')
            page.current = 1
            setList([])
            setHasMore(true)
            setVisible(false)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="afterSalesManage">
            <div className="header flex-space">
                {!focus ? <>
                    <div className="tabs flex">
                        {tabsList.map(item => (
                            <div
                                key={item.id}
                                className={`item ${type === item.id ? 'active' : ''}`}
                                onClick={() => setType(item.id)}
                            >{item.name}</div>
                        ))}
                    </div>
                    <div className="search-box" onClick={() => setFocus(true)}>
                        <span className="icon-search"></span>
                        <span>搜索</span>
                    </div>
                </> : <ZSearchBar
                    value={keywords}
                    autoFocus
                    placeholder="请输入订单编号"
                    onConfirm={(v) => {
                        setKeywords(v)
                        setFocus(false)
                    }}
                />}
            </div>
            <div className="tags flex-space">
                <div className="flex">
                    {statusList.map(item => (
                        <div
                            key={item.id}
                            className={`item-tag ${status === item.id ? 'active' : ''}`}
                            onClick={() => setStatus(item.id)}
                        >{item.name}</div>
                    ))}
                </div>
                <span className="icon-time" onClick={() => push('/afterSalesRecord')}></span>
            </div>
            <div className="content">
                {list.map(item => (<div className="card" key={item.id}>
                    <div className="item-header flex-space">
                        <div>{item.created_at}</div>
                        <div>订单编号：{item.order_no}</div>
                    </div>
                    {item.items.map(t => (<div className="item-goods flex" key={t.id}>
                        <div className="item-goods__media flex-shrink-0">
                            <Image className="item-goods__img" src={t.product_cover} />
                        </div>
                        <div className="item-goods__inner flex-1">
                            <div className="item-goods__title ell2">{t.product_name}</div>
                            <div className="item-goods__number">x{t.number}</div>
                        </div>
                    </div>))}
                    <div className="item-inner">
                        <div className="item-userInfo flex-a-center">
                            <img className="userInfo-img" src={item.user_cover} />
                            <span className="userInfo-name">{item.nickname}</span>
                            {!!item.mobile && <a href={`tel:${item.mobile}`} className="flex-a-center">
                                <span className="userInfo-phone">{item.mobile}</span>
                                <span className="icon-phone1"></span>
                            </a>}
                        </div>
                        {item.remark_type === 11
                            ? <div className="item-title">{item.remark_type_text}</div>
                            : <div className="item-title">{item.type === 1 ? '仅退款' : '退货退款'}：{item.remark_type_text}</div>}
                        {item.status === 2 ? <>
                            <div className="item-title">申请退款金额：￥{item.amount}</div>
                            <div className="item-title">损耗扣除金额：￥{item.deduct_price}</div>
                            <div className="flex-a-center">
                                <span>实际退款金额：</span>
                                <span className="item-amount">￥{item.refund_amount}</span>
                            </div>
                        </> : <div className="flex-a-center">
                            <span>退款金额：</span>
                            {item.status === 1 ? <span className="item-amount">￥{item.amount}</span> : <span>￥{item.amount}</span>}
                        </div>}
                    </div>
                    {item.status === 1 && <div className="item-btns flex flex-end">
                        <div className="item-btn" onClick={() => onBeforeReject(item)}>拒绝</div>
                        <div className="item-btn main" onClick={() => onBeforeOk(item)}>同意</div>
                    </div>}
                    {item.status === 2 && <div className="item-btns flex flex-end">
                        <div className="item-btn disabled">已退款</div>
                    </div>}
                    {item.status === 3 && <div className="item-btns flex flex-end">
                        <div className="item-btn disabled">已拒绝</div>
                    </div>}
                    {item.status === 4 && <div className="item-btns flex flex-end">
                        <div className="item-btn disabled">已撤销</div>
                    </div>}
                </div>))}
            </div>
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50} />
            <ZModal
                visible={visible}
                title="同意退款"
                className="ok-modal"
                showButton
                buttonText="同意退款"
                onClose={() => setVisible(false)}
                onConfirm={onOk}
            >
                <div className="ok-modal__cell flex-a-center">
                    <span className="item-label flex-shrink-0">申请退款金额</span>
                    <span className="item-title">¥{currentData.amount}</span>
                </div>
                <div className="ok-modal__cell border flex-a-center">
                    <span className="item-label flex-shrink-0">减除扣款</span>
                    <div className="flex-1">
                        <input type="number" className="item-input" value={amount} onChange={e => setAmount(e.target.value)} />
                    </div>
                    <span>元</span>
                </div>
                <div className="ok-modal__title">备注</div>
                <textarea className="ok-modal__textarea" value={remark} onChange={e => setRemark(e.target.value)}></textarea>
                <div className="ok-modal__footer flex-center">
                    <span>实际退款金额</span>
                    <div className="ok-modal__amount"><span className="unit">￥</span>{(currentData.amount - amount).toFixed(2)}</div>
                </div>
            </ZModal>
            <RejectModal ref={rejectModalRef} buttonText="拒绝退款" onConfirm={(v, hide) => {
                onReject(v).finally(() => {
                    hide()
                })
            }} />
        </div>
    )
}

export default AfterSalesManage