import http from './request'

// 货款账户
export const getTurnoverBalance = () => http.get('/store/mobile-turnover/turnover-balance')

// 货款账户-提现
export const addWithdraw = (params) => http.post('/store/mobile-withdraw/add-withdraw', params)

// 货款账户-账单明细
export const getTurnoverList = (params) => http.get('/store/mobile-turnover/turnover-list', params)

// 货款账户-提现记录
export const getWithdrawList = (params) => http.get('/store/mobile-withdraw/withdraw-list', params)