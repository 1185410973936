import React, { useEffect } from 'react'
import { Image } from 'antd-mobile-v5'
import { observer } from 'mobx-react'
import mainStore from '@/store/main'
import './index.scss'

const LicenseInfo = () => {
    const { info } = mainStore

    useEffect(() => {
        if (!info.id) {
            mainStore.getInfo()
        }
    }, [])

    return (
        <div className="infos-wrapper">
            <Image className="infos-img" src={info.business_license} fit="contain" />
            <div className="infos-inner">
                <div className="infos-title">营业执照</div>
                <div className="infos-cell flex-a-center">
                    <div className="item-label">法人姓名</div>
                    <div className="item-title flex-1">{info.corporation}</div>
                </div>
                <div className="infos-cell flex-a-center">
                    <div className="item-label">法人手机号</div>
                    <div className="item-title flex-1">{info.mobile}</div>
                </div>
                <div className="infos-cell flex-a-center">
                    <div className="item-label">法人身份证号</div>
                    <div className="item-title flex-1">{info.id_card_no}</div>
                </div>
            </div>
        </div>
    )
}

export default observer(LicenseInfo)