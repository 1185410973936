import React, { useEffect, useRef, useState } from 'react'
import { DotLoading, InfiniteScroll } from 'antd-mobile-v5'
import ZEmpty from '@/components/zEmpty'
import app from '@/app.json'
import { getWithdrawList } from '../../../apis/account'

const InfiniteScrollContent = ({ hasMore, isEmpty }) => {
    return (<>
        {hasMore ? (<>
            <span>加载中</span>
            <DotLoading />
        </>) : (
            !isEmpty ? <span>没有更多了</span> : <ZEmpty text="暂无提现记录～" />
        )}
    </>)
}

const tabsList = [
    { id: 0, name: '全部' },
    { id: 1, name: '进行中' },
    { id: 2, name: '已完成' },
]

const List2 = (props) => {
    const [status, setStatus] = useState(0)
    const page = useRef(1)
    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState(false)

    useEffect(() => {
        page.current = 1
        setList([])
        setHasMore(true)
    }, [status])

    async function loadMore() {
        try {
            const _page = page.current
            const { data } = await getWithdrawList({
                status,
                page: _page,
                limit: 10,
            })
            setList(_page === 1 ? data.data : [...list, ...data.data])
            setHasMore(data.has_more_pages)
            page.current += 1
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    return (
        <div>
            <div className="tags">
                {tabsList.map(item => (
                    <div
                        key={item.id}
                        className={`item-tag ${item.id === status ? 'active' : ''}`}
                        onClick={() => setStatus(item.id)}
                    >{item.name}</div>
                ))}
            </div>
            <div className="withdraw-list">
                {list.map((item, index) => (<div className="item-content flex" key={index}>
                    {item.withdraw_type === 1 && <img className="item-media flex-shrink-0" src={`${app.oss_host}mstore/icon-zfbpay.png`} />}
                    {item.withdraw_type === 2 && <img className="item-media flex-shrink-0" src={`${app.oss_host}mstore/icon-wxpay.png`} />}
                    {item.withdraw_type === 3 && <img className="item-media flex-shrink-0" src={`${app.oss_host}mstore/icon-yepay.png`} />}
                    <div className="item-inner flex-1 flex-space">
                        <div>
                            <div className="item-title">{item.withdraw_type_des}</div>
                            <div className="item-subtl">{item.created_at}</div>
                        </div>
                        <div className="flex-column align-end">
                            <div className="item-title">-¥{item.amount}</div>
                            <div className={`item-status ${item.status === 2 ? 'succ' : (item.status === 4 ? 'erro' : '')}`}>{item.status_name}</div>
                        </div>
                    </div>
                </div>))}
            </div>
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50}>
                <InfiniteScrollContent hasMore={hasMore} isEmpty={!list.length} />
            </InfiniteScroll>
        </div>
    )
}

export default List2