import React, { useEffect, useState } from 'react'
import { Dialog, Switch, Toast } from 'antd-mobile-v5'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import client from '@/graphql/apollo-config'
import {
    Add_Lei_Mu_Mutation,
    Delete_Lei_Mu_Mutation,
    Get_Store_Categorys,
    Xiu_Gai_Lei_Mu_Mutation,
} from '../../graphql/category'
import { categoryUpdateSort } from '../../apis/category'
import { showConfirm } from '../../utils'
import './index.scss'

const Category = (props) => {
    const [value, setValue] = useState('')
    const [visible, setVisible] = useState(false)
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getList(1)
    }, [])

    const getList = async(page) => {
        try {
            const { data } = await client.query({
                query: Get_Store_Categorys,
                variables: {
                    page,
                    limit: 100,
                },
                fetchPolicy: 'no-cache'
            })
            const _data = data.ProductCategories
            setList(page === 1 ? _data.data : [...list, ..._data.data])
            if (_data.has_more_pages) {
                getList(page + 1)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const refreshData = (item, index) => {
        const _list = [...list]
        _list.splice(index, 1, { ...item })
        setList(_list)
    }

    // 上下架
    const onSwitchChange = async(val, item, index) => {
        try {
            const { data } = await client.mutate({
                mutation: Xiu_Gai_Lei_Mu_Mutation,
                variables: {
                    product_category_id: item.id,
                    name: item.name,
                    sort_order: item.sort_order,
                    status: val ? 1 : 0
                },
                fetchPolicy: 'no-cache',
            });
            refreshData({
                ...item,
                status: data.updateProductCategory.status
            }, index)
        } catch (e) {
            console.log(e)
        }
    }

    // 删除
    const onDel = async(id, index) => {
        const res = await showConfirm({
            title: '删除类目',
            content: '是否确认删除该类目？',
        })
        if (!res) return
        try {
            const { data } = await client.mutate({
                mutation: Delete_Lei_Mu_Mutation,
                variables: { product_category_id: id },
                fetchPolicy: 'no-cache',
            });
            if (data) {
                const _list = [...list]
                _list.splice(index, 1)
                setList(_list)
                Toast.show('已删除')
            }
        } catch (e) {}
    }

    // 新增
    const onAdd = async() => {
        try {
            const { data } = await client.mutate({
                mutation: Add_Lei_Mu_Mutation,
                variables: {
                    name: value,
                    sort_order: 0,
                    status: 0
                },
                fetchPolicy: 'no-cache',
            });
            if (data.addProductCategory) {
                Toast.show('提交成功')
                getList(1)
                setVisible(false)
            }
        } catch (e) {
            console.log(e)
        }
        setLoading(false)
    }

    // 更新排序
    const updateSort = async(ids) => {
        try {
            await categoryUpdateSort({ category_ids: ids })
        } catch (e) {
            console.log(e)
        }
    }

    const onDragEnd = (result) => {
        if (!result.destination) return
        const newList = [...list]
        const startIndex = result.source.index
        const endIndex = result.destination.index
        const [item] = newList.splice(startIndex, 1)
        newList.splice(endIndex, 0, item)
        setList(newList)
        updateSort(newList.map(t => t.id))
    }

    const addCategory = () => {
        setValue('')
        setVisible(true)
    }

    return (
        <div className="category">
            <div className="header flex-a-center" onClick={addCategory}>
                <div className="icon-plus"></div>
                <span className="header-title">添加类目</span>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided) => (
                        <div className="category-list" {...provided.droppableProps} ref={provided.innerRef}>
                            {list.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            className="item-content flex-a-center"
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            style={{
                                                ...provided.draggableProps.style,
                                                opacity: snapshot.isDragging ? '0.7' : '1'
                                            }}
                                        >
                                            <div className="icon-reduce" onClick={() => onDel(item.id, index)}></div>
                                            <div className="item-inner flex-1 flex-a-center">
                                                <div className="item-title flex-1">{item.name}</div>
                                                <Switch
                                                    checked={item.status === 1}
                                                    onChange={(val) => onSwitchChange(val, item, index)}
                                                    style={{
                                                        '--adm-color-background': '#DCDFE6',
                                                        '--adm-color-border': '#DCDFE6',
                                                        '--checked-color': '#665BD4',
                                                        '--height': '24px',
                                                        '--width': '40px',
                                                    }}
                                                />
                                                <div className="icon-menu" {...provided.dragHandleProps}></div>
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <Dialog
                visible={visible}
                title="新增类目"
                content={(
                    <div style={{ padding: '0 10px' }}>
                        <input
                            value={value}
                            type="text"
                            className="category-input"
                            placeholder="类目名称"
                            onChange={e => setValue(e.target.value)}
                        />
                    </div>
                )}
                actions={[
                    [
                        {
                            key: 'cancel',
                            text: '取消',
                            style: { color: '#63626C', fontSize: 16 },
                            onClick: () => setVisible(false)
                        },
                        {
                            key: 'confirm',
                            text: '确定',
                            style: { color: '#665BD4', fontSize: 16 },
                            onClick: () => {
                                if (!value) {
                                    Toast.show('请输入类目名称')
                                    return
                                }
                                if (loading) return
                                setLoading(true)
                                onAdd()
                            }
                        }
                    ]
                ]}
            />
        </div>
    )
}

export default Category