import gql from 'graphql-tag'

// 批量删除
export const BATCH_PRODUCT = gql`
  mutation ($ids: [Int], $type: Int) {
    batchProduct(ids: $ids, type: $type)
  }
`;

// 用户信息
export const USER_AND_LOGISTICS = gql`
  query {
    oss {
      accessid
      host
      policy
      signature
      expire
      dir
      ossUrl
    }
    user {
      id
      store {
        id
        product_type
        delivery_type
        is_express
        status
        set_commission_rule
        store_category_id
        pos_code
        is_spend
        open_supplier
      }
    }
    storeInfo {
      store_base_audit {
        status
        is_first_audit
      }
    }
  }
`;

export const USABLE_LOGISTICS = gql`
  query ($delivery_type: Int) {
    usablelogistics(delivery_type: $delivery_type) {
      label
      value: id
    }
  }
`;

// 获取商店里商品信息
export const Get_Store_Categorys = gql`
  query ($limit: Int, $page: Int) {
    ProductCategories(limit: $limit, page: $page) {
      data {
        id
        name
        status
        sort_order
        created_at
        updated_at
      }
      total
      per_page
      current_page
      last_page
      has_more_pages
    }
  }
`;

export const GET_CATEGORY = gql`
  query ($parent_id: Int) {
    categorys(parent_id: $parent_id) {
      value: id
      label: name
      children: child {
        value: id
        label: name
      }
    }
  }
`;

// 商家店铺商品编辑
export const EDIT_GOODS = gql`
  mutation EditGoods(
    $product_id: Int!,
    $spu: String,
    $name: String!,
    $barcode: String,
    $keywords: String,
    $product_coupon: Int,
    $product_category_id: Int!,
    $order_limit: Int!,
    $category_ids: [Int]!,
    $sales_point: String!,
    $content: String!,
    $is_deposit: Int!,
    $status: Int!,
    $is_must: Int!,
    $supplier_id: Int,
    $video: String,
    $cover: String!,
    $images: [String]!,
    $price: Float!,
    $first_price: Float!,
    $rebate_rate: Float,
    $stock: Int!,
    $is_self_delivery: Int!,
    $delivery_type: Int!,
    $specs_types: String!,
    $product_offer_price: Float,
    $pusherman_redpack_rate: Float, 
    $service_redpack_rate: Float,
    $commission_rate: String,
    $numeral_coupon_id: Int,
  ){
    EditGoods(
      product_id: $product_id,
      spu: $spu,
      name: $name,
      barcode: $barcode,
      keywords: $keywords,
      product_coupon: $product_coupon,
      product_category_id: $product_category_id,
      order_limit: $order_limit,
      category_ids: $category_ids,
      sales_point: $sales_point,
      content: $content,
      is_deposit: $is_deposit,
      status: $status,
      is_must: $is_must,
      supplier_id: $supplier_id,
      video: $video,
      cover: $cover,
      images: $images,
      price: $price,
      first_price: $first_price,
      rebate_rate: $rebate_rate,
      stock: $stock,
      is_self_delivery: $is_self_delivery,
      delivery_type: $delivery_type,
      specs_types: $specs_types,
      product_offer_price: $product_offer_price,
      pusherman_redpack_rate: $pusherman_redpack_rate,
      service_redpack_rate: $service_redpack_rate,
      commission_rate: $commission_rate,
      numeral_coupon_id: $numeral_coupon_id,
    ){
      id
    }
  }
`;

// 商家店铺商品添加
export const ADD_GOODS = gql`
  mutation AddGoods(
    $spu: String,
    $name: String!,
    $barcode: String,
    $keywords: String,
    $product_coupon: Int,
    $product_category_id: Int!,
    $order_limit: Int!,
    $category_ids: [Int]!,
    $sales_point: String!,
    $content: String!,
    $is_deposit: Int!,
    $status: Int!,
    $is_must: Int!,
    $supplier_id: Int,
    $video: String,
    $cover: String!,
    $images: [String]!,
    $price: Float!,
    $first_price: Float!,
    $rebate_rate: Float,
    $stock: Int!,
    $is_self_delivery: Int!,
    $delivery_type: Int!,
    $specs_types: String!,
    $product_offer_price: Float,
    $pusherman_redpack_rate: Float, 
    $service_redpack_rate: Float,
    $commission_rate: String,
    $numeral_coupon_id: Int,
  ){
    AddGoods(
      spu: $spu,
      name: $name,
      barcode: $barcode,
      keywords: $keywords,
      product_coupon: $product_coupon,
      product_category_id: $product_category_id,
      order_limit: $order_limit,
      category_ids: $category_ids,
      sales_point: $sales_point,
      content: $content,
      is_deposit: $is_deposit,
      status: $status,
      is_must: $is_must,
      supplier_id: $supplier_id,
      video: $video,
      cover: $cover,
      images: $images,
      price: $price,
      first_price: $first_price,
      rebate_rate: $rebate_rate,
      stock: $stock,
      is_self_delivery: $is_self_delivery,
      delivery_type: $delivery_type,
      specs_types: $specs_types,
      product_offer_price: $product_offer_price,
      pusherman_redpack_rate: $pusherman_redpack_rate,
      service_redpack_rate: $service_redpack_rate,
      commission_rate: $commission_rate,
      numeral_coupon_id: $numeral_coupon_id,
    ){
      id
    }
  }
`;

// 商家店铺商品详情
export const GOODS_DETAIL = gql`
  query GoodsDetail($product_id: Int!){
    GoodsDetail(product_id: $product_id){
      id
      spu
      name
      barcode
      product_type
      product_coupon
      product_category_id
      numeral_coupon_id
      numeral_coupon_name
      sales_point
      content
      first_price
      product_offer_price
      price
      rebate_rate
      stock
      specs_num
      order_limit
      delivery_type
      module_type
      is_self_delivery
      is_must
      is_deposit
      total_write_off_count
      supplier
      supplier_id
      commission_rate
      pusherman_redpack_rate
      service_redpack_rate
      status
      is_hot
      weight
      is_warehouse
      cover {
        url
      }
      video {
        url
      }
      images {
        id
        url
      }
      categories {
        id
      }
      product_category {
        id
        name
      }
      specs_types{
        id
        name
        product_specs {
          id
          sku
          name
          price
          stock
          offer_price
        }
      }
    }
  }
`;