import React, { useImperativeHandle, useState } from 'react'
import { CenterPopup } from 'antd-mobile-v5'
import './index.scss'

const kefuEwm = require('@/assets/images/kefu-ewm.png')

const KefuModal = React.forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false)

    useImperativeHandle(ref, () => {
        return {
            show: () => {
                setVisible(true)
            }
        }
    })

    return (
        <CenterPopup
            visible={visible}
            bodyClassName="kefu-modal"
            onMaskClick={() => setVisible(false)}
            style={{
                '--min-width': 'auto'
            }}
        >
            <div className="kefu-modal__img">
                <img src={kefuEwm} alt="" />
            </div>
            <div className="kefu-modal__title">添加客服微信</div>
            <div className="kefu-modal__btn">
                <span className="icon-saoyisao"></span>
                <span>长按识别二维码</span>
            </div>
            <div className="kefu-modal__close" onClick={() => setVisible(false)}></div>
        </CenterPopup>
    )
})

export default KefuModal