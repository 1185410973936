import React, { useState } from 'react'
import { RightOutline } from 'antd-mobile-icons'
import './index.scss'

const Setup = (props) => {
    return (
        <div className="setup-wrapper">
            {/* <div className="item-cell flex-space">
                <div className="item-title">账号与安全</div>
                <div className="item-label flex-a-center">
                    <span>主账号</span>
                    <RightOutline />
                </div>
            </div> */}
            <div className="item-cell flex-space" onClick={() => {
                props.history.push('/systemNotice')
            }}>
                <div className="item-title">通知订阅</div>
                <RightOutline />
            </div>
            {/* <div className="item-cell flex-space">
                <div className="item-title">切换账号</div>
                <RightOutline />
            </div> */}
        </div>
    )
}

export default Setup