import http from './request'

// 店铺信息
export const getStoreInfo = () => http.get('/store/mobile-store/info')

// 修改店铺信息
export const setStoreInfo = (params) => http.post('/store/mobile-store/set-info', params)

// 商家后台-订单数据(外卖、物料、扫码点餐)
export const getOrderData = (params) => http.get('/store/mobile-order/order-data', params)

// 店铺-数据-总计
export const getStoreData = (params) => http.get('/store/mobile-store/store-data', params)
export const getStoreDataLine = (params) => http.get('/store/mobile-store/store-data-graph', params)

// 商圈-数据-总计
export const getBusinessData = (params) => http.get('/store/mobile-store/business-data', params)
export const getBusinessDataLine = (params) => http.get('/store/mobile-store/business-data-graph', params)