import React, { useEffect, useMemo, useState } from 'react'
import Echart from '@/components/echart'
import { isValidDateString } from '@/utils'
import { getBusinessData, getBusinessDataLine, getStoreData, getStoreDataLine } from '../../../apis/home'

const tabsList = [
    { id: 0, name: '店铺数据' },
    { id: 1, name: '商圈数据' },
]
const typeList = [
    { id: 1, name: '交易' },
    { id: 2, name: '商品' },
    { id: 3, name: '活动' },
    { id: 4, name: '任务' },
    { id: 5, name: '数字券' },
]
const dateTypeList = [
    { id: 'today', name: '实时' },
    { id: 'yesterday', name: '昨天' },
    { id: 'sevenDay', name: '7天' },
    { id: 'thirtyDay', name: '30天' },
]

const formatValue = (v, v2) => v !== undefined ? (v2 || v) : '--'

const DatasView = () => {
    const [tabActive, setTabActive] = useState(0)
    const [type, setType] = useState(1)
    const [dateType, setDateType] = useState('today')
    const [datas, setDatas] = useState({})
    const [lineOption, setLineOption] = useState({})
    const [currentItem, setCurrentItem] = useState({
        id: '',
        name: ''
    })

    const menus = useMemo(() => {
        const { yesterday = {} } = datas
        let list = []
        switch (type) {
        case 1:
            list = [
                {
                    id: 'sale_amount',
                    name: '成交金额(元)',
                    value: formatValue(datas.sale_amount),
                    yesterday: dateType === 'today' ? formatValue(yesterday.sale_amount, `¥${yesterday.sale_amount}`) : false
                },
                {
                    id: 'order_count',
                    name: '成交订单数',
                    value: formatValue(datas.order_count),
                    yesterday: dateType === 'today' ? formatValue(yesterday.order_count) : false
                },
                {
                    id: 'order_user',
                    name: '成交买家数',
                    value: formatValue(datas.order_user),
                    yesterday: dateType === 'today' ? formatValue(yesterday.order_user) : false
                },
                {
                    id: 'customer_unit_price',
                    name: '客单价(元)',
                    value: formatValue(datas.customer_unit_price),
                    yesterday: dateType === 'today' ? formatValue(yesterday.customer_unit_price, `¥${yesterday.customer_unit_price}`) : false
                },
                {
                    id: 'product_visit',
                    name: '商品访客数',
                    value: formatValue(datas.product_visit),
                    yesterday: dateType === 'today' ? formatValue(yesterday.product_visit) : false
                },
                {
                    id: 'conversion_rate',
                    name: '成交转化率',
                    value: formatValue(datas.conversion_rate),
                    yesterday: dateType === 'today' ? formatValue(yesterday.conversion_rate) : false
                },
                {
                    id: 'refund_amount',
                    name: '退款金额',
                    value: formatValue(datas.refund_amount),
                    yesterday: dateType === 'today' ? formatValue(yesterday.refund_amount, `${yesterday.refund_amount}%`) : false
                },
                {
                    id: 'refund_order_count',
                    name: '退款单数',
                    value: formatValue(datas.refund_order_count),
                    yesterday: dateType === 'today' ? formatValue(yesterday.refund_order_count, `${yesterday.refund_order_count}%`) : false
                },
                {
                    id: 'visit_value',
                    name: '访客价值',
                    value: formatValue(datas.visit_value),
                    yesterday: dateType === 'today' ? formatValue(yesterday.visit_value, `${yesterday.visit_value}%`) : false
                },
            ]
            break
        case 2:
            list = [
                {
                    id: 'product_visit',
                    name: '商品访客数',
                    value: formatValue(datas.product_visit),
                    yesterday: dateType === 'today' ? formatValue(yesterday.product_visit) : false
                },
                {
                    id: 'product_view',
                    name: '商品浏览量',
                    value: formatValue(datas.product_view),
                    yesterday: dateType === 'today' ? formatValue(yesterday.product_view) : false
                },
                {
                    id: 'visit_product_num',
                    name: '被访问商品数',
                    value: formatValue(datas.visit_product_num),
                    yesterday: dateType === 'today' ? formatValue(yesterday.visit_product_num) : false
                },
                {
                    id: 'store_visit',
                    name: '店铺访客数',
                    value: formatValue(datas.store_visit),
                    yesterday: dateType === 'today' ? formatValue(yesterday.store_visit) : false
                },
                {
                    id: 'store_view',
                    name: '店铺浏览量',
                    value: formatValue(datas.store_view),
                    yesterday: dateType === 'today' ? formatValue(yesterday.store_view) : false
                },
                {
                    id: 'finds_number',
                    name: '创作裂变',
                    value: formatValue(datas.finds_number),
                    yesterday: dateType === 'today' ? formatValue(yesterday.finds_number) : false
                },
            ]
            break
        case 3:
            list = [
                {
                    id: 'product_count',
                    name: '活动商品数',
                    value: formatValue(datas.product_count),
                    yesterday: dateType === 'today' ? formatValue(yesterday.product_count) : false
                },
                {
                    id: 'order_count',
                    name: '活动订单数',
                    value: formatValue(datas.order_count),
                    yesterday: dateType === 'today' ? formatValue(yesterday.order_count) : false
                },
                {
                    id: 'order_amount',
                    name: '活动成交额',
                    value: formatValue(datas.order_amount),
                    yesterday: dateType === 'today' ? formatValue(yesterday.order_amount) : false
                },
                {
                    id: 'product_visit',
                    name: '活动商品访客数',
                    value: formatValue(datas.product_visit),
                    yesterday: dateType === 'today' ? formatValue(yesterday.product_visit) : false
                },
                {
                    id: 'product_view',
                    name: '活动商品浏览量',
                    value: formatValue(datas.product_view),
                    yesterday: dateType === 'today' ? formatValue(yesterday.product_view) : false
                },
                {
                    id: 'share_count',
                    name: '分享裂变',
                    value: formatValue(datas.share_count),
                    yesterday: dateType === 'today' ? formatValue(yesterday.share_count) : false
                },
            ]
            break
        case 4:
            list = [
                {
                    id: 'order_count',
                    name: '任务订单量',
                    value: formatValue(datas.order_count),
                    yesterday: dateType === 'today' ? formatValue(yesterday.order_count) : false
                },
                {
                    id: 'delivery_order_count',
                    name: '正在派单',
                    value: formatValue(datas.delivery_order_count),
                    yesterday: dateType === 'today' ? formatValue(yesterday.delivery_order_count) : false
                },
                {
                    id: 'order_amount',
                    name: '订单金额',
                    value: formatValue(datas.order_amount),
                    yesterday: dateType === 'today' ? formatValue(yesterday.order_amount) : false
                },
                {
                    id: 'refund_order_count',
                    name: '任务退单量',
                    value: formatValue(datas.refund_order_count),
                    yesterday: dateType === 'today' ? formatValue(yesterday.refund_order_count) : false
                },
                {
                    id: 'finish_order_count',
                    name: '任务完成单量',
                    value: formatValue(datas.finish_order_count),
                    yesterday: dateType === 'today' ? formatValue(yesterday.finish_order_count) : false
                },
            ]
            break
        case 5: 
            list = [
                {
                    id: 'give_count',
                    name: '发券量',
                    value: formatValue(datas.give_count),
                    yesterday: dateType === 'today' ? formatValue(yesterday.give_count) : false
                },
                {
                    id: 'receive_count',
                    name: '领券量',
                    value: formatValue(datas.receive_count),
                    yesterday: dateType === 'today' ? formatValue(yesterday.receive_count) : false
                },
                {
                    id: 'use_count',
                    name: '用券量',
                    value: formatValue(datas.use_count),
                    yesterday: dateType === 'today' ? formatValue(yesterday.use_count) : false
                },
                {
                    id: 'new_user_count',
                    name: '拉新人数',
                    value: formatValue(datas.new_user_count),
                    yesterday: dateType === 'today' ? formatValue(yesterday.new_user_count) : false
                },
                {
                    id: 'order_amount',
                    name: '消费金额',
                    value: formatValue(datas.order_amount),
                    yesterday: dateType === 'today' ? formatValue(yesterday.order_amount) : false
                },
                {
                    id: 'share_count',
                    name: '分享裂变',
                    value: formatValue(datas.share_count),
                    yesterday: dateType === 'today' ? formatValue(yesterday.share_count) : false
                },
            ]
            break
        }
        if (list.length > 0 && JSON.stringify(datas) !== '{}') {
            setCurrentItem(list[0])
        }
        return list
    }, [datas])

    useEffect(() => {
        getData()
    }, [tabActive, type, dateType])

    useEffect(() => {
        if (currentItem.id) {
            getLines()
        }
    }, [currentItem])

    const getData = async() => {
        try {
            let data
            if (tabActive === 0) {
                const res = await getStoreData({ type: type, date_type: dateType })
                data = res.data
            } else {
                const res = await getBusinessData({ type: type, date_type: dateType })
                data = res.data
            }
            setDatas(data)
        } catch (e) {
            console.log(e)
        }
    }

    const getLines = async() => {
        try {
            const params = {
                type: type,
                date_type: dateType,
                graph_field: currentItem.id
            }
            let data
            if (tabActive === 0) {
                const res = await getStoreDataLine(params)
                data = res.data
            } else {
                const res = await getBusinessDataLine(params)
                data = res.data
            }
            const option = {
                color: ['#5E6FF5', '#FF7C29'],
                legend: {
                    show: dateType === 'today',
                    top: 6,
                    left: 'right',
                    icon: 'roundRect',
                    itemWidth: 12,
                    itemHeight: 12
                },
                tooltip: {
                    trigger: 'axis',
                    valueFormatter: (value) => currentItem.id === 'conversion_rate' ? `${value}%` : value
                },
                grid: {
                    containLabel: true,
                    top: 48,
                    left: 0,
                    right: 10,
                    bottom: 0
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: data.list.map(t => t.date_time),
                    axisLine: {
                        lineStyle: {
                            color: '#E5E6EB'
                        }
                    },
                    axisLabel: {
                        color: '#86909C',
                        formatter: function(value) {
                            if (isValidDateString(value)) {
                                return value.slice(5)
                            }
                            return value
                        }
                    },
                    axisTick: {
                        show: false
                    }
                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        lineStyle: {
                            color: '#F2F3F5',
                            type: 'dashed'
                        }
                    },
                    axisLabel: {
                        color: '#86909C'
                    }
                },
                series: [
                    {
                        name: dateType === 'today' ? '今日' : currentItem.name,
                        type: "line",
                        smooth: true,
                        showSymbol: false,
                        lineStyle: {
                            width: 2
                        },
                        areaStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: 'rgba(94, 111, 245, 0.5)' // 0% 处的颜色
                                }, {
                                    offset: 1, color: 'rgba(94, 111, 245, 0)' // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        },
                        data: data.list.map(t => t.value)
                    },
                ]
            }
            if (dateType === 'today') {
                option.series.push({
                    name: "昨日",
                    type: "line",
                    smooth: true,
                    showSymbol: false,
                    lineStyle: {
                        width: 2
                    },
                    areaStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0, color: 'rgba(255, 124, 41, 0.5)' // 0% 处的颜色
                            }, {
                                offset: 1, color: 'rgba(255, 124, 41, 0)' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        }
                    },
                    data: data.yesterday_list.map(t => t.value)
                })
            }
            setLineOption(option)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="chart-card">
            <div className="item-header">
                {tabsList.map(item => (
                    <div
                        key={item.id}
                        className={`item-title ${item.id === tabActive ? 'active' : ''}`}
                        onClick={() => setTabActive(item.id)}
                    >{item.name}</div>
                ))}
            </div>
            <div className="item-content">
                <div className="item-tabs flex">
                    {typeList.map(item => (
                        <div
                            key={item.id}
                            className={`item-tab ${item.id === type ? 'active' : ''}`}
                            onClick={() => setType(item.id)}
                        >{item.name}</div>
                    ))}
                </div>
                <div className="item-tags flex-space">
                    <div className="block-title">{typeList.find(t => t.id === type).name}概况</div>
                    <div className="item-tags__inner flex">
                        {dateTypeList.map(item => (
                            <div
                                key={item.id}
                                className={`item-tag ${item.id === dateType ? 'active' : ''}`}
                                onClick={() => setDateType(item.id)}
                            >{item.name}</div>
                        ))}
                    </div>
                </div>
                <div className="item-grids">
                    {menus.map(item => (<div key={item.id}  className={`item-grid ${currentItem.id === item.id ? 'active' : ''}`} onClick={() => setCurrentItem(item)}>
                        <div className="item-grid__title">{item.name}</div>
                        <div className="item-grid__total">{item.value}</div>
                        {item.yesterday !== false && <div className="item-grid__subtl">昨日 {item.yesterday}</div>}
                    </div>))}
                </div>
                <div className="chart-box">
                    <div className="block-title">{currentItem.name}</div>
                    <Echart options={lineOption} />
                </div>
            </div>
        </div>
    )
}

export default DatasView