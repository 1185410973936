import http from './request'

// 商家后台-数字券列表
export const getNumeralCouponList = (params) => http.get('/store/numeral-coupon/list', params)

// 数字券活动提前开始 / 提前结束
export const couponEditTime = (params) => http.post('/store/numeral-coupon/edit-time', params)

// 数字券删除
export const couponDel = (params) => http.post('/store/numeral-coupon/del', params)

// 指定用户列表
export const getCouponUserList = (params) => http.get('/store/numeral-coupon/specify-user', params)

// 可叠加券列表
export const getCouponStackingList = (params) => http.get('/store/numeral-coupon/stacking-list', params)

// 数字券添加
export const couponAdd = (params) => http.post('/store/numeral-coupon/add', params)

// 数字券详情
export const getCouponDetail = (params) => http.get('/store/numeral-coupon/detail', params)