import http from './request'

// 任务活动-列表
export const getTaskList = (params) => http.get('/store/mobile-task-activity/list', params)

// 接单资格-待审批数量
export const getTaskAuditNum = () => http.get('/store/mobile-task-activity/receive-order-audit-notice')

// 接单资格审批列表
export const getTaskAuditList = (params) => http.get('/store/mobile-task-activity/receive-order-audit-list', params)

// 接单资格审批-通过、驳回
export const taskExamine = (params) => http.post('/store/mobile-task-activity/receive-order-examine', params)

// 任务活动-详情
export const getTaskDetail = (params) => http.get('/store/mobile-task-activity/detail', params)

// 任务活动-取消订单[待付款][待帮助]
export const onTaskCancel = (params) => http.post('/store/mobile-task-activity/cancel', params)

// 任务活动-暂停打款（延长付款）
export const onTaskDelayPay = (params) => http.post('/store/mobile-task-activity/delay-pay', params)

// 任务活动-完成订单
export const onTaskFinish = (params) => http.post('/store/mobile-task-activity/finish', params)

// 任务活动-私信记录列表
export const getTaskMessageList = (params) => http.get('/store/mobile-task-activity/message-list', params)