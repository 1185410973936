import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { DatePicker, Image, ImageUploader, Toast } from 'antd-mobile-v5'
import { RightOutline } from 'antd-mobile-icons'
import CouponModal from '../../components/couponModal'
import GoodsModal from '../../components/goodsModal'
import { uploadImages } from '../../apis/common'
import app from '@/app.json'
import './index.scss'
import { timestampToString, verification } from '../../utils'
import { addActivity, getActivityDetail } from '../../apis/activity'
import qs from 'query-string'

const now = new Date()
async function mockUpload(file) {
    try {
        const formData = new FormData()
        formData.append('file', file)
        const { data } = await uploadImages(formData)
        return {
            url: app.oss_host + data
        }
    } catch (e) {
        console.log(e)
        return Promise.reject(e)
    }
}

const UploadVideoItem = (originNode, file, fileList) => {
    return (
        <div className="adm-image-uploader-cell" key={originNode.key}>
            <Image className="adm-image-uploader-cell-image" src={file.url + '?x-oss-process=video/snapshot,t_1050,f_jpg'} />
            <div className="icon-play"></div>
            {originNode.props.deletable && <span className="adm-image-uploader-cell-delete" onClick={originNode.props.onDelete}>
                {originNode.props.deleteIcon}
            </span>}
        </div>
    )
}

const AddActivity = (props) => {
    const history = useHistory()
    const { id } = qs.parse(useLocation().search)
    const [dateVisible, setDateVisible] = useState(false)
    const [couponVisible, setCouponVisible] = useState(false)
    const [goodsVisible, setGoodsVisible] = useState(false)
    const [form, setValue] = useState({
        cover: [],
        video_url: [],
        title: '',
        des: '',
        type_id: 1,
        end_time: '',
        numeral_coupon_arr: [],
        product_arr: [],
        is_join: 1,
    })
    const couponModalRef = useRef()
    const goodsModalRef = useRef()

    useEffect(() => {
        if (id) {
            getInfo()
        }
    }, [])

    const setFormValue = (key, value) => {
        setValue({
            ...form,
            [key]: value
        })
    }

    const getInfo = async() => {
        try {
            const { data } = await getActivityDetail({ id })
            setValue({
                cover: data.cover ? [{ url: data.cover }] : [],
                video_url: data.video_url ? [{ url: data.video_url }] : [],
                title: data.title,
                des: data.des,
                type_id: 1,
                end_time: data.end_time,
                numeral_coupon_arr: [],
                product_arr: [],
                is_join: 1,
            })
        } catch (e) {
            console.log(e)
        }
    }

    const delCouponItem = (index) => {
        const list = [...form.numeral_coupon_arr]
        list.splice(index, 1)
        setFormValue('numeral_coupon_arr', list)
        couponModalRef.current && couponModalRef.current.reset(list)
    }

    const delGoodItem = (index) => {
        const list = [...form.product_arr]
        list.splice(index, 1)
        setFormValue('product_arr', list)
        goodsModalRef.current && goodsModalRef.current.reset(list)
    }

    const rule = [
        {
            rule: !form.cover.length,
            title: '请上传封面'
        },
        {
            rule: !form.video_url.length,
            title: '请上传视频'
        },
        {
            rule: !form.title,
            title: '请输入标题'
        },
        {
            rule: !form.end_time,
            title: '请选择结束时间'
        },
        {
            rule: !form.numeral_coupon_arr.length,
            title: '请选择数字券'
        },
        {
            rule: !form.des,
            title: '请输入活动介绍'
        },
        {
            rule: !form.product_arr.length,
            title: '请选择活动商品'
        },
    ]

    const onSubmit = async() => {
        if (!verification(rule)) return
        try {
            const { data } = await addActivity({
                ...form,
                cover: form.cover[0].url,
                video_url: form.video_url[0].url,
                numeral_coupon_arr: JSON.stringify(
                    form.numeral_coupon_arr.map(t => ({
                        numeral_coupon_id: t.id,
                        numeral_coupon_name: t.name
                    }))
                ),
                product_arr: JSON.stringify(
                    form.product_arr.map(t => ({
                        product_id: t.product_id,
                        product_name: t.product_name,
                        store_id: t.store_id
                    }))
                )
            })
            Toast.show('提交成功')
            history.goBack()
        } catch(e) {
            console.log(e)
        }
    }

    return (
        <div className="addActivity">
            <div className="content">
                <div className="addActivity-upload">
                    <ImageUploader
                        value={form.cover}
                        accept="image/*"
                        maxCount={1}
                        showUpload={!form.cover.length}
                        deleteIcon={<div className="icon-close"></div>}
                        upload={mockUpload}
                        onChange={v => setFormValue('cover', v)}
                    >
                        <div className="upload-btn">
                            <div className="icon-upload"></div>
                            <span className="upload-btn__text">上传封面</span>
                        </div>
                    </ImageUploader>
                    <ImageUploader
                        value={form.video_url}
                        accept="video/*"
                        maxCount={1}
                        showUpload={!form.video_url.length}
                        deleteIcon={<div className="icon-close"></div>}
                        renderItem={UploadVideoItem}
                        upload={mockUpload}
                        onChange={v => setFormValue('video_url', v)}
                    >
                        <div className="upload-btn">
                            <div className="icon-upload"></div>
                            <span className="upload-btn__text">上传视频</span>
                        </div>
                    </ImageUploader>
                    <div className="upload-text">上传封面和视频，文件大小不得超过1GB</div>
                </div>
                <div className="block-title">
                    <div className="title">活动标题</div>
                    <div className="required">*</div>
                </div>
                <div className="title-textarea">
                    <textarea className="textarea" value={form.title} maxLength={25} onChange={e => setFormValue('title', e.target.value)} />
                    <div className="len">{form.title.length}/25</div>
                </div>
                <div className="block-title">
                    <div className="title">招募属性</div>
                    <div className="required">*</div>
                </div>
                <div className="cells">
                    <div className="cell border" onClick={() => setDateVisible(true)}>
                        <div className="item-label">结束时间</div>
                        {form.end_time
                            ? <div>{form.end_time}<RightOutline /></div>
                            : <div className="item-placeholder">请选择<RightOutline /></div>}
                    </div>
                    <div className="cell" onClick={() => setCouponVisible(true)}>
                        <div className="item-label">联盟优惠券</div>
                        <div className="item-placeholder">请选择<RightOutline /></div>
                    </div>
                    {form.numeral_coupon_arr.length > 0 && <>
                        <div className="coupon-modal-tips">
                            <div className="icon-tips"></div>
                            <div>优惠券的领取时间和使用店铺范围将会随联盟券更改</div>
                        </div>
                        <div className="coupon-modal-list">
                            {form.numeral_coupon_arr.map((item, index) => (<div className="item-content flex-a-center" key={item.id}>
                                <div className="icon-close item-close" onClick={() => delCouponItem(index)}></div>
                                <div className="item-media flex-column align-center flex-shrink-0">
                                    <div className="item-amount">
                                        {item.type != 2 && <div className="unit">¥</div>}
                                        <div className="amount">{+item.money}</div>
                                        {item.type == 2 && <div className="unit">折</div>}
                                    </div>
                                    <div className="item-text">{item.discount_desc}</div>
                                </div>
                                <div className="item-inner flex-1">
                                    <div className="item-title ell1">{item.name}</div>
                                    <div className="item-subtl">{item.expiration_date}到期</div>
                                </div>
                            </div>))}
                        </div>
                    </>}
                    <div className="cell column">
                        <div className="item-label">活动介绍</div>
                        <div className="item-textarea">
                            <textarea className="item-textarea__inner" value={form.des} maxLength={800} onChange={e => setFormValue('des', e.target.value)} />
                            <div className="len">{form.des.length}/800</div>
                        </div>
                    </div>
                </div>
                <div className="block-title" style={{ margin: 0 }}>
                    <div className="title">活动商品</div>
                    <div className="required">*</div>
                </div>
                <div className="goods">
                    {form.product_arr.map((item, index) => (<div className="item-content" key={item.product_id}>
                        <div className="item-media">
                            <Image className="item-img" src={item.cover} />
                            <div className="icon-close" onClick={() => delGoodItem(index)}></div>
                        </div>
                        <div className="item-title ell1">{item.product_name}</div>
                    </div>))}
                    <div className="add-goods" onClick={() => setGoodsVisible(true)}>
                        <img className="add-goods__img" src={`${app.oss_host}static/gongtou/icon/icon-addGoods.png`} />
                        <div className="add-goods__label">上传商品</div>
                    </div>
                </div>
                <div className="submit-btn" onClick={onSubmit}>发布</div>
            </div>
            <DatePicker
                title="结束时间"
                visible={dateVisible}
                defaultValue={now}
                onClose={() => setDateVisible(false)}
                onConfirm={(v) => setFormValue('end_time', timestampToString(v, 1))}
            />
            <CouponModal
                ref={couponModalRef}
                title="选择优惠券"
                tips="优惠券的领取时间和使用店铺范围将会随联盟券更改"
                visible={couponVisible}
                onClose={() => setCouponVisible(false)}
                onConfirm={(v) => setFormValue('numeral_coupon_arr', v)}
            />
            <GoodsModal
                ref={goodsModalRef}
                title="选择商品"
                visible={goodsVisible}
                onClose={() => setGoodsVisible(false)}
                onConfirm={(v) => setFormValue('product_arr', v)}
            />
        </div>
    )
}

export default AddActivity