/*
 * @Author: Fan
 * @Date: 2020-12-01 10:27:52
 * @LastEditors: Fan
 * @LastEditTime: 2020-12-02 16:57:23
 * @Description: file content
 */
import React from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import Login from "@/page/login";
import Index from "@/page/index";
import Resources from "@/page/resources";
import OtherInfo from "@/page/otherInfo";
import Invitation from "@/page/invitation";
import Record from "@/page/record";
import Notice from "@/page/notice";
import Home from '@/page/home';
import GoodsManage from '@/page/goodsManage';
import AddGoods from '@/page/addGoods';
import Category from '@/page/category';
import StoreInfo from '@/page/storeInfo';
import BankInfo from '@/page/infos/bankInfo'
import IdcardInfo from '@/page/infos/idcardInfo'
import LicenseInfo from '@/page/infos/licenseInfo'
import Setup from '@/page/setup'
import SystemNotice from '@/page/systemNotice'
import Activity from '@/page/activity'
import JoinStoreList from '@/page/joinStoreList'
import StoreExamine from '@/page/storeExamine'
import ActivityManage from '@/page/activityManage'
import AddActivity from '@/page/addActivity'
import CouponManage from '@/page/couponManage'
import AddCoupon from '@/page/addCoupon'
import TaskManage from '@/page/taskManage'
import TaskDetail from '@/page/taskDetail'
import TaskChat from '@/page/taskChat'
import TaskExamine from '@/page/taskExamine'
import AfterSalesManage from '@/page/afterSalesManage'
import AfterSalesRecord from '@/page/afterSalesRecord'
import AccountFunds from '@/page/accountFunds'
import BindingBank from '@/page/bindingBank'

const setTitle = (title) => {
  document.title = title;
};
const RouterList = [
  { title: "登录", path: "/login", component: Login },
  { title: "注册", path: "/register", component: Login },
  { title: "忘记密码", path: "/forgetpwd", component: Login },
  { title: "协议", path: "/resources", component: Resources },
  { title: "基本信息", path: "/", component: Index, requiresAuth: true },
  { title: "通知", path: "/notice", component: Notice, requiresAuth: true },
  {
    title: "典栈生活圈",
    path: "/otherinfo",
    component: OtherInfo,
    requiresAuth: true,
  },
  {
    title: "扫码付款记录",
    path: "/record",
    component: Record,
    requiresAuth: true,
  },
  {
    title: "邀请人数",
    path: "/invitation",
    component: Invitation,
    requiresAuth: true,
  },
  { title: "店铺管理", path: "/home", component: Home, requiresAuth: true },
  { title: "商品管理", path: "/goodsManage", component: GoodsManage, requiresAuth: true },
  { title: "发布商品", path: "/addGoods", component: AddGoods, requiresAuth: true },
  { title: "编辑商品", path: "/editGoods", component: AddGoods, requiresAuth: true },
  { title: "类目管理", path: "/category", component: Category, requiresAuth: true },
  { title: "店铺信息", path: "/storeInfo", component: StoreInfo, requiresAuth: true },
  { title: "银行信息", path: "/bankInfo", component: BankInfo, requiresAuth: true },
  { title: "身份信息", path: "/idcardInfo", component: IdcardInfo, requiresAuth: true },
  { title: "营业执照", path: "/licenseInfo", component: LicenseInfo, requiresAuth: true },
  { title: "设置", path: "/setup", component: Setup, requiresAuth: true },
  { title: "系统通知订阅", path: "/systemNotice", component: SystemNotice, requiresAuth: true },
  { title: "活动管理", path: "/activityManage", component: ActivityManage, requiresAuth: true },
  { title: "活动管理", path: "/addActivity", component: AddActivity, requiresAuth: true },
  { title: "我的活动", path: "/activity", component: Activity, requiresAuth: true },
  { title: "报名商家列表", path: "/joinStoreList", component: JoinStoreList, requiresAuth: true },
  { title: "店铺审核", path: "/storeExamine", component: StoreExamine, requiresAuth: true },
  { title: "数字券管理", path: "/couponManage", component: CouponManage, requiresAuth: true },
  { title: "发布数字券", path: "/addCoupon", component: AddCoupon, requiresAuth: true },
  { title: "编辑数字券", path: "/editCoupon", component: AddCoupon, requiresAuth: true },
  { title: "查看数字券", path: "/seeCoupon", component: AddCoupon, requiresAuth: true },
  { title: "任务管理", path: "/taskManage", component: TaskManage, requiresAuth: true },
  { title: "订单详情", path: "/taskDetail", component: TaskDetail, requiresAuth: true },
  { title: "历史聊天", path: "/taskChat", component: TaskChat, requiresAuth: true },
  { title: "接单资格审批", path: "/taskExamine", component: TaskExamine, requiresAuth: true },
  { title: "售后管理", path: "/afterSalesManage", component: AfterSalesManage, requiresAuth: true },
  { title: "处理记录", path: "/afterSalesRecord", component: AfterSalesRecord, requiresAuth: true },
  { title: "账户资金", path: "/accountFunds", component: AccountFunds, requiresAuth: true },
  { title: "提现银行卡", path: "/bindingBank", component: BindingBank, requiresAuth: true },
];
// 需要token权限的路由
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      setTitle(rest.title);
      return Cookies.get("token") ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);
const RouteItemDom = (item) => {
  return item.requiresAuth ? (
    <PrivateRoute
      exact
      path={item.path}
      key={item.path}
      title={item.title}
      component={item.component}
    />
  ) : (
    <Route
      exact
      path={item.path}
      key={item.path}
      title={item.title}
      render={(props) => {
        setTitle(item.title);
        return <item.component {...props} />;
      }}
    />
  );
};

const BasicRoute = () => (
  <BrowserRouter>
    <Switch>
      {/* exact 严格模式，统一开启 */}
      {RouterList.map((item) => RouteItemDom(item))}
    </Switch>
  </BrowserRouter>
);

export default BasicRoute;
