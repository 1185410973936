import axios from 'axios'
import Cookies from 'js-cookie'
import { Toast } from 'antd-mobile';

axios.defaults.headers.common['Content-Type'] = 'multipart/form-data'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.withCredentials = false


const noTipCode = [41027];
const notLoginCode = [401, 402, 40002, "Unauthenticated", "Unauthorized", "您的账号已被禁用", "未登陆"];

const service = axios.create({
    baseURL: ''
})

// http request(请求) 拦截器
service.interceptors.request.use(
    (config) => {
        const token = Cookies.get('token')
        if (token) {
            config.headers.common['Authorization'] = token
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// http response(响应) 拦截器
service.interceptors.response.use(
    response => {
        // console.log('success', response)
        if (!response.data.errors) return Promise.reject(response.data)
        const { errors } = response.data
        const { code, message: msg } = Array.isArray(errors) ? errors[0] : errors
        if (code !== 1) {
            const isNoTip = noTipCode.some(item => item === code);
            if (!isNoTip) {
                Toast.info(msg)
            }
            const notLogin = notLoginCode.some(item => item === code || msg.indexOf(item) !== -1)
            if (notLogin) {
                Cookies.remove('token')
                Cookies.remove('mobile')
                Cookies.remove('store_id')
                setTimeout(() => {
                    window.location.reload()
                }, 1000)
            }
            return Promise.reject(response.data)
        }
        return response.data
    },
    error => {
        // console.log('error:', error.response)
        if (error.response.status === 500) {
            Toast.info('网络异常，请重试')
        } else if (error.response.status === 401) {
            Cookies.remove('token')
            Cookies.remove('mobile')
            Cookies.remove('store_id')
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        }
        return Promise.reject(error.response)
    }
)

class Api {
    /**
	 * 封装get方法
	 * @param url
	 * @param params
	 * @returns {Promise}
	*/
    get(url, params = {}) {
        return new Promise((resolve, reject) => {
            service.get(url, { params: params })
                .then((res) => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }

    /**
	 * 封装post请求
	 * @param url
	 * @param data
	 * @returns {Promise}
	 */
    post(url, data = {}) {
        return new Promise((resolve, reject) => {
            service.post(url, data)
                .then(res => {
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
}

export default new Api()
