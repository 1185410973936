import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Image, InfiniteScroll, Popup, Toast } from 'antd-mobile-v5'
import ZFooterBar from '@/components/zFooterBar'
import ZSearchBar from '@/components/zSearchBar'
import client from '@/graphql/apollo-config'
import { BATCH_PRODUCT } from '@/graphql/goods'
import { getBadgeNum, getProductList, setGoodsStatus } from '@/apis/goods'
import { showConfirm } from '@/utils'
import './index.scss'

const filterList = [
    { id: 'created_at_desc', name: '最新创建在上' },
    { id: 'created_at_asc', name: '最新创建在下' },
    { id: 'updated_at_desc', name: '最新更新在上' },
    { id: 'updated_at_asc', name: '最新更新在下' },
    { id: 'sales_num_desc', name: '30日销量从高到低' },
    { id: 'sales_num_asc', name: '30日销量从低到高' },
    { id: 'stock_desc', name: '库存从高到低' },
    { id: 'stock_asc', name: '库存从低到高' },
]

const GoodsManage = (props) => {
    const { push } = useHistory()
    const page = useRef(1)
    const [type, setType] = useState(0)
    const [field, setField] = useState('created_at_desc')
    const [params, setParams] = useState({})
    const [nums, setNums] = useState({
        all_num: 0,
        sales_num: 0,
        off_num: 0,
        few_num: 0,
    })
    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [visible1, setVisible1] = useState(false)
    const [visible2, setVisible2] = useState(false)
    const [isBatch, setIsBatch] = useState(false)
    const [focus, setFocus] = useState(false)
    const [keywords, setKeywords] = useState('')

    const [isAllChecked, checkedIds] = useMemo(() => {
        const arr = list.filter(t => t.checked)
        return [arr.length === list.length, arr.map(t => t.id)]
    }, [list])

    const typeList = useMemo(() => ([
        { id: 0, name: `全部(${nums.all_num})` },
        { id: 1, name: `售卖(${nums.sales_num})` },
        { id: 2, name: `下架(${nums.off_num})` },
        { id: 3, name: `缺货(${nums.few_num})` },
    ]), [nums])

    useEffect(() => {
        getNums()
    }, [])

    useEffect(() => {
        page.current = 1
        setList([])
        setHasMore(true)
        setVisible1(false)
        setVisible2(false)
    }, [type, field, params, keywords])

    const onReset = () => {
        document.getElementById('filterForm').reset()
        setParams({})
    }
    const onFormOk = () => {
        const form = document.getElementById('filterForm')
        const formData = new FormData(form)
        if (formData.get('price_min') && formData.get('price_max') && formData.get('price_min') > formData.get('price_max')) {
            Toast.show('请输入正确的价格区间(元)')
            return
        }
        if (formData.get('sale_amount_min') && formData.get('sale_amount_max') && formData.get('sale_amount_min') > formData.get('sale_amount_max')) {
            Toast.show('请输入正确的累计销量')
            return
        }
        const _data = {}
        for (const [key, value] of formData.entries()) {
            !!value && (_data[key] = value)
        }
        setParams(_data)
    }

    const getNums = async() => {
        const { data } = await getBadgeNum()
        setNums(data)
    }

    async function loadMore() {
        try {
            const _page = page.current
            const { data } = await getProductList({
                ...params,
                type,
                field,
                keywords,
                page: _page,
                limit: 10,
            })
            setList(_page === 1 ? data.data : [...list, ...data.data])
            setHasMore(data.has_more_pages)
            page.current += 1
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    // 下架
    const onRemove = async (id) => {
        const res = await showConfirm({
            title: '下架选中商品',
            content: '下架商品后，将放弃商圈流量红利，是否继续？',
            cancelText: '暂不下架',
            confirmText: '仍要下架',
        })
        if (res) {
            setStatus(id, 0)
        }
    }

    // 上下架
    const setStatus = async(ids, status) => {
        const { data } = await setGoodsStatus({
            product_ids: ids,
            status
        })
        Toast.show(status === 1 ? '上架成功' : '下架成功')
        page.current = 1
        setList([])
        setHasMore(true)
        getNums()
    }

    // 全选
    const onAllClick = () => {
        const _list = list.map(t => {
            const checked = !isAllChecked
            return {
                ...t,
                checked
            }
        })
        setList(_list)
    }

    // 单选
    const onItemClick = (item, index) => {
        const _list = [...list]
        _list.splice(index, 1, {
            ...item,
            checked: !item.checked
        })
        setList(_list)
    }

    // 批量删除
    const onDel = async () => {
        if (checkedIds.length === 0) return
        const res = await showConfirm({
            title: '删除选中商品',
            content: '是否确认删除选择的商品？',
            cancelText: '取消',
            confirmText: '确定',
        })
        if (!res) return
        try {
            await client.query({
                query: BATCH_PRODUCT,
                variables: {
                    ids: checkedIds,
                    type: 1
                },
                fetchPolicy: 'no-cache'
            })
            Toast.show('删除成功')
            page.current = 1
            setList([])
            setHasMore(true)
            getNums()
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="goodsManage">
            <div className="header flex-space">
                {!focus ? <>
                    <div className="tabs flex">
                        {typeList.map(item => (
                            <div
                                key={item.id}
                                className={`item ${type === item.id ? 'active' : ''}`}
                                onClick={() => setType(item.id)}
                            >{item.name}</div>
                        ))}
                    </div>
                    <div className="search-box" onClick={() => setFocus(true)}>
                        <span className="icon-search"></span>
                        <span>搜索</span>
                    </div>
                </> : <ZSearchBar
                    value={keywords}
                    autoFocus
                    placeholder="请输入内容"
                    onConfirm={(v) => {
                        setKeywords(v)
                        setFocus(false)
                    }}
                />}
            </div>
            <div className="filter-box flex-space">
                <div className={`item flex-a-center ${visible1 ? 'active' : ''}`} onClick={() => {
                    setVisible1(!visible1)
                    setVisible2(false)
                }}>
                    <span className="item-name">{filterList.find(t => t.id === field).name}</span>
                    <span className={`icon-unfold ${visible1 ? 'active' : ''}`}></span>
                </div>
                <div className="flex">
                    <div className={`item flex-a-center ${visible2 ? 'active' : ''}`} onClick={() => {
                        setVisible1(false)
                        setVisible2(!visible2)
                    }}>
                        <span className="item-name">筛选</span>
                        <span className={`icon-sort ${visible2 ? 'active' : ''}`}></span>
                    </div>
                    <div className={`item flex-a-center ${isBatch ? 'active' : ''}`} onClick={() => {
                        setVisible1(false)
                        setVisible2(false)
                        setIsBatch(!isBatch)
                    }}>
                        <span className="item-name">{isBatch ? '取消批量' : '批量'}</span>
                        {!isBatch && <span className="icon-document"></span>}
                    </div>
                </div>
            </div>
            <div className="goods-list">
                {list.map((item, index) => (<div className="item flex-a-center" key={item.id}>
                    {isBatch && <div className={`icon-checkbox ${item.checked ? 'active' : ''}`} onClick={() => onItemClick(item, index)}></div>}
                    <div className="item-content flex-1">
                        <div className="flex">
                            <div className="item-media flex-shrink-0">
                                <Image className="item-img" src={item.cover} />
                            </div>
                            <div className="item-inner flex-1">
                                <div className="item-title ell1">{item.name}</div>
                                <div className="item-subtl flex">
                                    <span>累计销量 {item.sale_count}</span>
                                    <span>30日销量 {item.sales_num_30}</span>
                                    <span>库存 {item.stock}</span>
                                </div>
                                <div className="item-amount">￥{item.price}</div>
                            </div>
                        </div>
                        {!isBatch && <div className="item-footer flex flex-end">
                            {item.status === 1
                                ? <div className="item-btn" onClick={() => onRemove(item.id)}>下架</div> 
                                : <div className="item-btn" onClick={() => setStatus(item.id, 1)}>上架</div>}
                            <div className="item-btn main" onClick={() => push(`/editGoods?id=${item.id}`)}>编辑</div>
                        </div>}
                    </div>
                </div>))}
            </div>
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50} />

            {isBatch && <ZFooterBar>
                <div>
                    <div className="footer-all flex-a-center" onClick={onAllClick}>
                        <span className={`icon-checkbox ${isAllChecked ? 'active' : ''}`}></span>
                        <span className="footer-all-text">全选</span>
                    </div>
                    <div className="footer-text">已选<span className="num">{checkedIds.length}</span>个商品</div>
                </div>
                <div className="flex">
                    <div className="footer-btn" onClick={() => {
                        if (checkedIds.length === 0) return
                        setStatus(checkedIds.join(','), 1)
                    }}>上架</div>
                    <div className="footer-btn" onClick={() => {
                        if (checkedIds.length === 0) return
                        onRemove(checkedIds.join(','))
                    }}>下架</div>
                    <div className="footer-btn main" onClick={onDel}>删除</div>
                </div>
            </ZFooterBar>}

            <Popup
                visible={visible1}
                className="filter-popup"
                position="top"
                onMaskClick={() => setVisible1(false)}
            >
                <div className="filter-list">
                    {filterList.map(item => (
                        <div
                            key={item.id}
                            className={`item-content flex-space ${field === item.id ? 'active' : ''}`}
                            onClick={() => setField(item.id)}
                        >
                            <div className="item-title">{item.name}</div>
                            {item.id === field && <div className="icon-dagou"></div>}
                        </div>
                    ))}
                </div>
            </Popup>
            <Popup
                visible={visible2}
                className="filter-popup"
                position="top"
                onMaskClick={() => setVisible2(false)}
            >
                <form id="filterForm" className="sort-popup">
                    <div className="item">
                        <div className="item-title">价格区间(元)</div>
                        <div className="item-inner">
                            <input name="price_min" type="number" className="item-input" placeholder="最低价" />
                            <div className="item-space"></div>
                            <input name="price_max" type="number" className="item-input" placeholder="最高价" />
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-title">累计销量</div>
                        <div className="item-inner">
                            <input name="sale_amount_min" type="number" className="item-input" placeholder="最低值" />
                            <div className="item-space"></div>
                            <input name="sale_amount_max" type="number" className="item-input" placeholder="最高值" />
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-title">是否新品</div>
                        <div className="item-inner">
                            <label>
                                <input type="radio" name="new_product" value={1} />
                                <div className="item-btn">新品</div>
                            </label>
                            <label>
                                <input type="radio" name="new_product" value={2} />
                                <div className="item-btn">非新品</div>
                            </label>
                        </div>
                    </div>
                    <div className="sort-popup-footer">
                        <div className="sort-popup-btn" onClick={onReset}>重置</div>
                        <div className="sort-popup-btn main flex-1" onClick={onFormOk}>确认</div>
                    </div>
                </form>
            </Popup>
        </div>
    )
}

export default GoodsManage