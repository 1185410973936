import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { DatePicker, Image, Picker, Toast } from 'antd-mobile-v5'
import { RightOutline } from 'antd-mobile-icons'
import CouponModal from '../../components/couponModal'
import GoodsModal from '../../components/goodsModal'
import UserModal from '../../components/userModal'
import ZFooterBar from '../../components/zFooterBar'
import app from '@/app.json'
import qs from 'query-string'
import './index.scss'
import { timestampToString, timeStrToTimestamp, verification } from '../../utils'
import { couponAdd, getCouponDetail } from '../../apis/coupon'

const now = new Date()

const typeList = [
    { id: 1, name: '满减券' },
    { id: 2, name: '折扣券' },
]
const effectiveList = [
    { id: 1, name: '领取后生效' },
    { id: 2, name: '固定时间' },
]
const timeTypeList = [
    { id: 1, name: '不限' },
    { id: 2, name: '每周' },
    { id: 3, name: '每月' },
]
const usableGoods = [
    { value: 1, label: '全部可用' },
    { value: 2, label: '指定可用' },
    { value: 3, label: '指定不参与' },
    // { value: 4, label: '可用分类' },
]
const usableUser = [
    { value: 1, label: '全部用户' },
    { value: 2, label: '新用户' },
    { value: 3, label: '购买指定商品' },
    { value: 6, label: '指定用户' },
    { value: 7, label: '成功拉新用户' },
]
const weekColumns = [
    { value: 1, label: '星期一' },
    { value: 2, label: '星期二' },
    { value: 3, label: '星期三' },
    { value: 4, label: '星期四' },
    { value: 5, label: '星期五' },
    { value: 6, label: '星期六' },
    { value: 7, label: '星期日' },
]
const monthColumns = Array.from({ length: 30 }).map((t, index) => ({ value: index +1, label: `${index+1}号` }))

const AddCoupon = (props) => {
    const history = useHistory()
    const { search, pathname } = useLocation()
    const { id } = qs.parse(search)
    const disabled = useMemo(() => pathname.includes('seeCoupon'), [])
    const [couponVisible, setCouponVisible] = useState(false)
    const [goodsVisible, setGoodsVisible] = useState(false)
    const [userVisible, setUserVisible] = useState(false)
    const [form, setValue] = useState({
        category_type: 1,
        type: 1,
        money: '',
        name: '',
        full: '',
        max_num: '',
        start_time: '',
        end_time: '',
        is_effective: 1,
        effective_start_time: '',
        effective_end_time: '',
        use_time_type: 1,
        use_time_number: '',
        usable_store: 1,
        // usable_store_arr: [],
        usable_goods: 1,
        usable_goods_arr: [],
        // usable_goods_category_id: [],
        usable_user: 1,
        usable_user_goods_arr: [],
        usable_user_specify_arr: [],
        limit_number: '',
        stacking_coupon_arr: [],
    })
    const currentKey = useRef('')
    const goodsModalRef = useRef()
    const userModalRef = useRef()
    const couponModalRef = useRef()

    useEffect(() => {
        if (id) {
            getData()
        }
    }, [])

    const setFormValue = (key, value) => {
        setValue({
            ...form,
            [key]: value
        })
    }
    const setFormValues = (newValue) => {
        setValue({
            ...form,
            ...newValue
        })
    }

    const getData = async() => {
        try {
            const { data } = await getCouponDetail({ id })
            setValue({
                category_type: data.category_type,
                type: data.type,
                money: +data.money + '',
                name: data.name,
                full: +data.full + '',
                max_num: data.max_num + '',
                start_time: data.start_time.split(' ')[0],
                end_time: data.end_time.split(' ')[0],
                is_effective: data.is_effective,
                effective_start_time: data.is_effective === 2 ? data.effective_start_time.split(' ')[0] : '',
                effective_end_time: data.effective_end_time.split(' ')[0],
                use_time_type: data.use_time_type,
                use_time_number: data.use_time_number,
                usable_store: data.usable_store,
                usable_goods: data.usable_goods,
                usable_goods_arr: data.usable_goods_arr || [],
                usable_user: data.usable_user,
                usable_user_goods_arr: data.usable_user_goods_arr || [],
                usable_user_specify_arr: data.usable_user_specify_arr || [],
                limit_number: data.limit_number,
                stacking_coupon_arr: data.stacking_coupon_arr || [],
            })
        } catch (e) {
            console.log(e)
        }
    }

    // 选择日期
    const onDateChange = async(key) => {
        if (disabled) return
        let title = ''
        if (key === 'start_time' || key === 'effective_start_time') {
            title = '开始时间'
        } else if (key === 'end_time' || (key === 'effective_end_time' && form.is_effective === 2)) {
            title = '结束时间'
        } else if (key === 'effective_end_time' && form.is_effective === 1) {
            title = '截止时间'
        }
        let date = now
        if (form[key]) {
            const [y,m,d] = form[key].split('-')
            date = new Date(+y, m - 1, +d)
        }
        const value = await DatePicker.prompt({
            title,
            defaultValue: date,
        })
        value && setFormValue(key, timestampToString(value, 1))
    }

    const onTimeNumberChange = async() => {
        if (disabled) return
        const value = await Picker.prompt({
            title: form.use_time_type === 2 ? '选择星期' : '选择星期',
            defaultValue: [form.use_time_number],
            columns: [form.use_time_type === 2 ? weekColumns : monthColumns],
        })
        value && setFormValue('use_time_number', value[0])
    }

    const showGoodsModal = (key) => {
        currentKey.current = key
        goodsModalRef.current && goodsModalRef.current.reset(form[key])
        setGoodsVisible(true)
    }
    const onDelItem = (key, index) => {
        const list = [...form[key]]
        list.splice(index, 1)
        setFormValue(key, list)
    }

    const showUserModal = (key) => {
        currentKey.current = key
        userModalRef.current && userModalRef.current.reset(form[key])
        setUserVisible(true)
    }

    const rule = [
        {
            rule: !form.name,
            title: '请输入数字券名称'
        },
        {
            rule: !form.money,
            title: `请输入${form.type === 1 ? '满减金额' : '折扣率'}`
        },
        {
            rule: form.type === 1 && +form.money <= 0,
            title: '满减金额必须大于0'
        },
        {
            rule: form.type === 2 && (+form.money < 0 || +form.money > 1),
            title: '请输入0-1的值'
        },
        {
            rule: !form.full,
            title: '请输入使用门槛'
        },
        {
            rule: +form.full < 0,
            title: '使用门槛必须大于等于0'
        },
        {
            rule: !form.max_num,
            title: '请输入发行数量'
        },
        {
            rule: +form.max_num < 1000 || +form.max_num > 1000000,
            title: '发行数量需要大于等于1000，不超过1000万'
        },
        {
            rule: !form.limit_number,
            title: '请输入每人限领'
        },
        {
            rule: form.limit_number > 100,
            title: '每人限领最多不超过100'
        },
        {
            rule: !form.start_time,
            title: '请选择开始时间'
        },
        {
            rule: !form.end_time,
            title: '请选择结束时间'
        },
        {
            rule: timeStrToTimestamp(form.start_time) > timeStrToTimestamp(form.end_time),
            title: '开始时间不能大于结束时间'
        },
        {
            rule: form.is_effective === 1 && !form.effective_end_time,
            title: '请选择截止时间'
        },
        {
            rule: form.is_effective === 2 && !form.effective_start_time,
            title: '请选择生效开始时间'
        },
        {
            rule: form.is_effective === 2 && !form.effective_end_time,
            title: '请选择生效结束时间'
        },
        {
            rule: form.is_effective === 2 &&  timeStrToTimestamp(form.effective_start_time) > timeStrToTimestamp(form.effective_end_time),
            title: '生效开始时间不能大于结束时间'
        },
        {
            rule: form.use_time_type != 1 && !form.use_time_number,
            title: `请选择${form.use_time_type === 2 ? '星期几' : '日期'}`
        },
        {
            rule: form.usable_goods != 1 && !form.usable_goods_arr.length,
            title: '请上传“可用商品”'
        },
        {
            rule: form.usable_user === 3 && !form.usable_user_goods_arr.length,
            title: '请上传“可领用户”指定商品'
        },
        {
            rule: form.usable_user === 6 && !form.usable_user_specify_arr.length,
            title: '请上传“可领用户”指定用户'
        },
    ]

    const onSubmit = async() => {
        if (!verification(rule)) return
        try {
            const params = {
                ...form,
                start_time: `${form.start_time} 00:00:00`,
                end_time: `${form.end_time} 23:59:59`,
                effective_start_time: form.is_effective === 2 ? `${form.effective_start_time} 00:00:00` : undefined,
                effective_end_time: `${form.effective_end_time} 23:59:59`,
                usable_goods_arr: [2, 3].includes(form.usable_goods) ? form.usable_goods_arr.map(t => ({
                    product_id: t.product_id,
                    product_name: t.product_name
                })) : undefined,
                usable_user_goods_arr: form.usable_user === 3 ? form.usable_user_goods_arr.map(t => ({
                    product_id: t.product_id,
                    product_name: t.product_name
                })) : undefined,
                usable_user_specify_arr: form.usable_user === 6 ? form.usable_user_specify_arr.map(t => ({
                    id: t.id,
                    nickname: t.nickname
                })) : undefined,
                stacking_coupon_arr: form.stacking_coupon_arr.map(t => ({
                    id: t.id,
                    name: t.name
                }))
            }
            if (id) {
                params.id = id
            }
            const { data } = await couponAdd(params)
            Toast.show('提交成功')
            setTimeout(() => {
                history.goBack()
            }, 2000)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="addCoupon">
            <div className="card">
                <div className="block-title flex">数字券名称<span className="red">*</span></div>
                <div className="item-textarea">
                    <textarea value={form.name} maxLength={25} disabled={disabled} onChange={e => setFormValue('name', e.target.value)}></textarea>
                    <div className="len">{form.name.length}/25</div>
                </div>
            </div>
            <div className="card">
                <div className="block-title flex">面额信息<span className="red">*</span></div>
                <div className="cells">
                    <div className="item-cell flex-space">
                        <div className="item-cell__label">数字券类型</div>
                        <div className="flex">
                            {typeList.map(item => (
                                <div
                                    key={item.id}
                                    className={`item-radio ${form.type === item.id ? 'active' : ''}`}
                                    onClick={() => {
                                        if (disabled) return
                                        setFormValues({
                                            type: item.id,
                                            money: ''
                                        })
                                    }}
                                >
                                    <span className={`icon-radio ${form.type === item.id ? 'active' : ''}`}></span>
                                    <span>{item.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="item-cell border">
                        <div className="item-cell__label" style={{ color: '#1B1A1E' }}>{form.type === 1 ? '满减金额' : '折扣率'}</div>
                        <div className="item-cell__amount flex-a-center">
                            {form.type === 1 && <span className="unit">¥</span>}
                            <div className="flex-1">
                                <input
                                    type="number"
                                    value={form.money}
                                    disabled={disabled}
                                    placeholder={form.type === 1 ? '' : '请输入0-1的值'}
                                    onChange={e => setFormValue('money', e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="item-cell border flex-space">
                        <div className="item-cell__label">使用门槛</div>
                        <div className="flex-a-center">
                            <span>满</span>
                            <input
                                type="number"
                                className="item-input m6"
                                value={form.full}
                                disabled={disabled}
                                placeholder="0元即无门槛"
                                onChange={e => setFormValue('full', e.target.value)}
                            />
                            <span>元使用</span>
                        </div>
                    </div>
                    <div className="item-cell border flex-space">
                        <div className="item-cell__label">发行数量</div>
                        <div className="flex-a-center flex-1">
                            <div className="flex-1">
                                <input
                                    type="number"
                                    className="item-cell__input"
                                    placeholder="需要大于等于1000，不超过1000万"
                                    value={form.max_num}
                                    disabled={disabled}
                                    onChange={e => setFormValue('max_num', e.target.value)}
                                />
                            </div>
                            <span>张</span>
                        </div>
                    </div>
                    <div className="item-cell flex-space">
                        <div className="item-cell__label">每人限领</div>
                        <div className="flex-a-center flex-1">
                            <div className="flex-1">
                                <input
                                    type="number"
                                    className="item-cell__input"
                                    placeholder="最多不超过100"
                                    value={form.limit_number}
                                    disabled={disabled}
                                    onChange={e => setFormValue('limit_number', e.target.value)}
                                />
                            </div>
                            <span>张</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="block-title flex">基本信息<span className="red">*</span></div>
                <div className="cells">
                    <div className="item-cell border flex-space">
                        <div className="item-cell__label">活动时间</div>
                        <div className="flex-a-center">
                            <input
                                className="item-input"
                                value={form.start_time}
                                placeholder="开始日期"
                                readOnly
                                onClick={() => onDateChange('start_time')}
                            />
                            <span className="icon-jiantou m10"></span>
                            <input
                                className="item-input"
                                value={form.end_time}
                                placeholder="结束日期"
                                readOnly
                                onClick={() => onDateChange('end_time')}
                            />
                        </div>
                    </div>
                    <div className="item-cell flex-space">
                        <div className="item-cell__label">生效时间</div>
                        <div className="flex">
                            {effectiveList.map(item => (
                                <div
                                    key={item.id}
                                    className={`item-radio ${form.is_effective === item.id ? 'active' : ''}`}
                                    onClick={() => {
                                        if (disabled) return
                                        setFormValues({
                                            is_effective: item.id,
                                            effective_start_time: '',
                                            effective_end_time: '',
                                        })
                                    }}
                                >
                                    <span className={`icon-radio ${form.is_effective === item.id ? 'active' : ''}`}></span>
                                    <span>{item.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="item-cell border flex-space">
                        <div></div>
                        {form.is_effective === 1 ? (
                            <input
                                value={form.effective_end_time}
                                className="item-input w132"
                                placeholder="请选择截止时间"
                                readOnly
                                onClick={() => onDateChange('effective_end_time')}
                            />
                        ) : (
                            <div className="flex-a-center">
                                <input
                                    className="item-input"
                                    value={form.effective_start_time}
                                    placeholder="开始日期"
                                    readOnly
                                    onClick={() => onDateChange('effective_start_time')}
                                />
                                <span className="icon-jiantou m10"></span>
                                <input
                                    className="item-input"
                                    value={form.effective_end_time}
                                    placeholder="结束日期"
                                    readOnly
                                    onClick={() => onDateChange('effective_end_time')}
                                />
                            </div>
                        )}
                    </div>
                    <div className="item-cell flex-space">
                        <div className="item-cell__label">使用时间</div>
                        <div className="flex">
                            {timeTypeList.map(item => (
                                <div
                                    key={item.id}
                                    className={`item-radio ${form.use_time_type === item.id ? 'active' : ''}`}
                                    onClick={() => {
                                        if (disabled) return
                                        setFormValues({
                                            use_time_type: item.id,
                                            use_time_number: ''
                                        })
                                    }}
                                >
                                    <span className={`icon-radio ${form.use_time_type === item.id ? 'active' : ''}`}></span>
                                    <span>{item.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                    {form.use_time_type != 1 && <div className="item-cell flex-space">
                        <div></div>
                        <div className="item-input flex-a-center w132" onClick={onTimeNumberChange}>
                            {!form.use_time_number
                                ? <span className="item-input__placeholder">{form.use_time_type === 2 ? '请选择星期几' : '请选择日期'}</span>
                                : <span>{form.use_time_type === 2
                                    ? weekColumns.find(t => t.value === form.use_time_number).label
                                    : `${form.use_time_number}号`
                                }</span>}
                        </div>
                    </div>}
                </div>
            </div>
            <div className="card" style={{ paddingTop: 0 }}>
                <div className="cells">
                    <Picker
                        title="可用商品"
                        columns={[usableGoods]}
                        value={[form.usable_goods]}
                        onConfirm={v => {
                            v && setFormValue('usable_goods', v[0])
                        }}
                    >
                        {(_, actions) => <div className="item-cell flex-space" onClick={!disabled ? actions.open : null}>
                            <div className="item-cell__label">可用商品<span className="red">*</span></div>
                            <div className="flex-a-center">
                                <span>{_[0].label}</span>
                                <RightOutline />
                            </div>
                        </div>}
                    </Picker>
                    {[2,3].includes(form.usable_goods) && <div className="goods border">
                        {form.usable_goods_arr.map((item, index) => (<div className="item-content" key={item.product_id}>
                            <div className="item-media">
                                <Image className="item-img" src={item.cover} />
                                {!disabled && <div className="icon-close" onClick={() => onDelItem('usable_goods_arr', index)}></div>}
                            </div>
                            <div className="item-title ell1">{item.product_name}</div>
                        </div>))}
                        {!disabled && <div className="add-goods" onClick={() => showGoodsModal('usable_goods_arr')}>
                            <img className="add-goods__img" src={`${app.oss_host}static/gongtou/icon/icon-addGoods.png`} />
                            <div className="add-goods__label">上传商品</div>
                        </div>}
                    </div>}
                    <Picker
                        title="可领用户"
                        columns={[usableUser]}
                        value={[form.usable_user]}
                        onConfirm={v => {
                            v && setFormValue('usable_user', v[0])
                        }}
                    >
                        {(_, actions) => <div className="item-cell flex-space" onClick={!disabled ? actions.open : null}>
                            <div className="item-cell__label">可领用户<span className="red">*</span></div>
                            <div className="flex-a-center">
                                <span>{_[0].label}</span>
                                <RightOutline />
                            </div>
                        </div>}
                    </Picker>
                    {form.usable_user === 3 && <div className="goods border">
                        {form.usable_user_goods_arr.map((item, index) => (<div className="item-content" key={item.product_id}>
                            <div className="item-media">
                                <Image className="item-img" src={item.cover} />
                                {!disabled && <div className="icon-close" onClick={() => onDelItem('usable_user_goods_arr', index)}></div>}
                            </div>
                            <div className="item-title ell1">{item.product_name}</div>
                        </div>))}
                        {!disabled && <div className="add-goods" onClick={() => showGoodsModal('usable_user_goods_arr')}>
                            <img className="add-goods__img" src={`${app.oss_host}static/gongtou/icon/icon-addGoods.png`} />
                            <div className="add-goods__label">上传商品</div>
                        </div>}
                    </div>}
                    {form.usable_user === 6 && <div className="goods border">
                        {form.usable_user_specify_arr.map((item, index) => (<div className="item-content" key={item.id}>
                            <div className="item-media">
                                <Image className="item-img" src={item.avatar} />
                                {!disabled && <div className="icon-close" onClick={() => onDelItem('usable_user_specify_arr', index)}></div>}
                            </div>
                            <div className="item-title ell1">{item.nickname}</div>
                        </div>))}
                        {!disabled && <div className="add-goods" onClick={() => showUserModal('usable_user_specify_arr')}>
                            <img className="add-goods__img" src={`${app.oss_host}static/gongtou/icon/icon-addGoods.png`} />
                            <div className="add-goods__label">选择用户</div>
                        </div>}
                    </div>}
                    <div className="item-cell flex-space" onClick={() => {
                        if (disabled) return
                        couponModalRef.current && couponModalRef.current.reset(form.stacking_coupon_arr)
                        setCouponVisible(true)
                    }}>
                        <div className="item-cell__label">优惠叠加</div>
                        <div className="flex-a-center">
                            <span className="item-cell__subtl">请选择</span>
                            <RightOutline />
                        </div>
                    </div>
                </div>
                {form.stacking_coupon_arr.length > 0 && <>
                    <div className="coupon-modal-tips">
                        <div className="icon-tips"></div>
                        <div>不选择则默认其他优惠券不可与本次优惠券叠加使用</div>
                    </div>
                    <div className="coupon-modal-list">
                        {form.stacking_coupon_arr.map((item, index) => (<div className="item-content flex-a-center" key={item.id}>
                            {!disabled && <div className="icon-close item-close" onClick={() => onDelItem('stacking_coupon_arr', index)}></div>}
                            <div className="item-media flex-column align-center flex-shrink-0">
                                <div className="item-amount">
                                    {item.type != 2 && <div className="unit">¥</div>}
                                    <div className="amount">{+item.money}</div>
                                    {item.type == 2 && <div className="unit">折</div>}
                                </div>
                                <div className="item-text">{item.discount_desc}</div>
                            </div>
                            <div className="item-inner flex-1">
                                <div className="item-title ell1">{item.name}</div>
                                <div className="item-subtl">{item.expiration_date}到期</div>
                            </div>
                        </div>))}
                    </div>
                </>}
            </div>

            {!disabled && <ZFooterBar>
                <div className="footer-btn flex-1" onClick={onSubmit}>{id ? '编辑' : '新建'}</div>
            </ZFooterBar>}
            <CouponModal
                ref={couponModalRef}
                stacking
                title="选择优惠券"
                tips="不选择则默认其他优惠券不可与本次优惠券叠加使用"
                visible={couponVisible}
                onClose={() => setCouponVisible(false)}
                onConfirm={(v) => setFormValue('stacking_coupon_arr', v)}
            />
            <GoodsModal
                ref={goodsModalRef}
                title="选择商品"
                visible={goodsVisible}
                onClose={() => setGoodsVisible(false)}
                onConfirm={(v) => setFormValue(currentKey.current, v)}
            />
            <UserModal
                ref={userModalRef}
                title="选择用户"
                visible={userVisible}
                onClose={() => setUserVisible(false)}
                onConfirm={(v) => setFormValue(currentKey.current, v)}
            />
        </div>
    )
}

export default AddCoupon