import http from './request'

// 商品管理-列表
export const getProductList = (params) => http.get('/store/mobile-product/list', params)

// 商品管理-列表-标记数量
export const getBadgeNum = () => http.get('/store/mobile-product/get-badge-num')

// 商品管理-商品下架、上架
export const setGoodsStatus = (params) => http.post('/store/mobile-product/set-goods-sale-status', params)

