import React, { useImperativeHandle, useRef, useState } from 'react'
import { Image, InfiniteScroll, Toast } from 'antd-mobile-v5'
import ZModal from '../zModal'
import './index.scss'
import { getActivityGoodsList } from '../../apis/activity'

const GoodsModal = React.forwardRef(({ title, type = 'multiple', visible, onClose, onConfirm }, ref) => {
    const page = useRef(1)
    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [checkedList, setCheckedList] = useState([])

    const loadMore = async() => {
        try {
            const _page = page.current
            const { data } = await getActivityGoodsList({
                page: _page,
                limit: 10,
            })
            setList(_page === 1 ? data.data : [...list, ...data.data])
            setHasMore(data.has_more_pages)
            page.current += 1
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    const onItemChange = (item) => {
        if (type === 'multiple') {
            const index = checkedList.findIndex(t => t.product_id === item.product_id)
            if (index === -1) {
                setCheckedList([...checkedList, item])
            } else {
                const _list = [...checkedList]
                _list.splice(index, 1)
                setCheckedList(_list)
            }
        } else {
            setCheckedList([item])
        }
    }

    const onSubmit = () => {
        if (!checkedList.length) {
            Toast.show('请选择商品')
            return
        }
        onConfirm && onConfirm(checkedList)
        onClose && onClose()
    }

    useImperativeHandle(ref, () => {
        return {
            reset: (v) => {
                setCheckedList(v || [])
            }
        }
    })

    return (
        <ZModal
            title={title}
            className="goods-modal"
            visible={visible}
            onClose={onClose}
            showButton
            onConfirm={onSubmit}
        >
            <div className="goods-modal-inner">
                <div className="goods-modal-list">
                    {list.map((item, index) => (<div className="item-content flex" key={item.product_id}>
                        <div className="item-media flex-shrink-0">
                            <Image className="item-img" src={item.cover} />
                        </div>
                        <div className="item-inner flex-1">
                            <div className="item-title ell2">{item.product_name}</div>
                            <div className="item-amount">¥{item.price}</div>
                        </div>
                        <div className={`icon-checkbox item-checkbox ${checkedList.some(t => t.product_id === item.product_id) ? 'active' : ''}`} onClick={() => onItemChange(item, index)}></div>
                    </div>))}
                </div>
                <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50} />
            </div>
        </ZModal>
    )
})

export default GoodsModal