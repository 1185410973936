import React from 'react'
import { Popup } from 'antd-mobile-v5'
import './index.scss'

const ZModal = ({
    visible,
    title,
    children,
    isClose = true,
    showButton = false,
    buttonText = '确定',
    position = 'bottom',
    className,
    onClose,
    onConfirm,
}) => {

    return (
        <Popup
            visible={visible}
            bodyClassName={`z-modal ${className || ''}`}
            position={position}
            onMaskClick={onClose}
        >
            {!!title && <div className="z-modal-title">{title}</div>}
            {isClose && <div className="z-modal-close" onClick={onClose}></div>}
            {children}
            {showButton && <div className="z-modal-footer">
                <div className="z-modal-btn" onClick={onConfirm}>{buttonText}</div>
            </div>}
        </Popup>
    )
}

export default ZModal