import React, { useImperativeHandle, useRef, useState } from 'react'
import { InfiniteScroll, SearchBar, Toast } from 'antd-mobile-v5'
import ZModal from '../zModal'
import './index.scss'
import { getCouponStackingList, getNumeralCouponList } from '../../apis/coupon'

const CouponModal = React.forwardRef(({
    visible,
    title,
    tips = '',
    status = 0,
    stacking = false,
    type = 'multiple',
    showSearch = false,
    onClose,
    onConfirm
}, ref) => {
    const page = useRef(1)
    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [keywords, setKeywords] = useState('')
    const [checkedList, setCheckedList] = useState([])

    // 获取数字券列表
    const loadMore1 = async() => {
        try {
            const _page = page.current
            const { data } = await getNumeralCouponList({
                page: _page,
                limit: 10,
                status,
                keywords
            })
            setList(_page === 1 ? data.data : [...list, ...data.data])
            setHasMore(data.has_more_pages)
            page.current += 1
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    // 获取叠加券
    const loadMore2 = async() => {
        try {
            const _page = page.current
            const { data } = await getCouponStackingList({
                page: _page,
                limit: 10,
                keywords
            })
            setList(_page === 1 ? data.data : [...list, ...data.data])
            setHasMore(data.has_more_pages)
            page.current += 1
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    const onItemChange = (item) => {
        if (type === 'multiple') {
            const index = checkedList.findIndex(t => t.id === item.id)
            if (index === -1) {
                setCheckedList([...checkedList, item])
            } else {
                const _list = [...checkedList]
                _list.splice(index, 1)
                setCheckedList(_list)
            }
        } else {
            setCheckedList([item])
        }
    }

    const onSubmit = () => {
        if (!checkedList.length) {
            Toast.show('请选择数字券')
            return
        }
        onConfirm && onConfirm(checkedList)
        onClose && onClose()
    }

    const onSearch = (v) => {
        page.current = 1
        setKeywords(v)
        setList([])
        setHasMore(true)
    }

    useImperativeHandle(ref, () => {
        return {
            reset: (v) => {
                setCheckedList(v || [])
            }
        }
    })

    return (
        <ZModal
            title={title}
            className="coupon-modal"
            visible={visible}
            onClose={onClose}
            showButton
            onConfirm={onSubmit}
        >
            {showSearch && <SearchBar placeholder='请输入关键词搜索' onClear={() => onSearch('')} onSearch={onSearch} />}
            {!!tips && <div className="coupon-modal-tips">
                <div className="icon-tips"></div>
                <div>{tips}</div>
            </div>}
            <div className="coupon-modal-inner">
                <div className="coupon-modal-list">
                    {list.map((item, index) => (<div className="item-content flex-a-center" key={item.id}>
                        <div className="item-media flex-column align-center flex-shrink-0">
                            <div className="item-amount">
                                {item.type != 2 && <div className="unit">¥</div>}
                                <div className="amount">{+item.money}</div>
                                {item.type == 2 && <div className="unit">折</div>}
                            </div>
                            <div className="item-text">{item.discount_desc}</div>
                        </div>
                        <div className="item-inner flex-1">
                            <div className="item-title ell1">{item.name}</div>
                            <div className="item-subtl">{item.expiration_date}到期</div>
                        </div>
                        <div className={`icon-checkbox item-checkbox ${checkedList.some(t => t.id === item.id) ? 'active' : ''}`} onClick={() => onItemChange(item, index)}></div>
                    </div>))}
                </div>
                <InfiniteScroll loadMore={stacking ? loadMore2 : loadMore1} hasMore={hasMore} threshold={50} />
            </div>
        </ZModal>
    )
})

export default CouponModal