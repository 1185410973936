import React from 'react'
import { Image, ImageUploader } from 'antd-mobile-v5'
import './index.scss'
import { uploadImages } from '../../apis/common'
import app from '@/app.json'

async function mockUpload(file) {
    try {
        const formData = new FormData()
        formData.append('file', file)
        const { data } = await uploadImages(formData)
        return {
            url: app.oss_host + data
        }
    } catch (e) {
        console.log(e)
        return Promise.reject(e)
    }
}

const UploadVideoItem = (originNode, file, fileList) => {
    return (
        <div className="adm-image-uploader-cell" key={originNode.key}>
            <Image className="adm-image-uploader-cell-image" src={file.url + '?x-oss-process=video/snapshot,t_1050,f_jpg'} />
            <div className="icon-play"></div>
            {originNode.props.deletable && <span className="adm-image-uploader-cell-delete" onClick={originNode.props.onDelete}>
                {originNode.props.deleteIcon}
            </span>}
        </div>
    )
}

const Upload = (props) => {
    const {
        value = [],
        type = 'image', // image | video
        count = 1,
        title = '',
        disabled = false,
        required = false,
        onChange,
    } = props

    return (
        <div className="upload">
            <div className="upload-title flex">{title}{required && <span className="upload-required">*</span>}</div>
            <div className="upload-inner">
                {type === 'image' && <ImageUploader
                    value={value}
                    accept="image/*"
                    maxCount={count}
                    deletable={!disabled}
                    showUpload={!disabled}
                    disableUpload={disabled}
                    deleteIcon={<div className="icon-close"></div>}
                    upload={mockUpload}
                    onChange={onChange}
                >
                    <div className="upload-add">
                        <span className="icon-uploadImg"></span>
                        <span className="upload-add-label">{value.length}/{count}</span>
                    </div>
                </ImageUploader>}
                {type === 'video' && <ImageUploader
                    value={value}
                    accept="video/*"
                    maxCount={count}
                    deletable={!disabled}
                    showUpload={!disabled}
                    disableUpload={disabled}
                    deleteIcon={<div className="icon-close"></div>}
                    renderItem={UploadVideoItem}
                    upload={mockUpload}
                    onChange={onChange}
                >
                    <div className="upload-add">
                        <span className="icon-uploadVideo"></span>
                        <span className="upload-add-label">{value.length}/{count}</span>
                    </div>
                </ImageUploader>}
            </div>
        </div>
    )
}

export default Upload