import React, { useEffect, useMemo, useState } from 'react'

const filterNumber = (n) => (n+'').padStart(2, '0')

const ZCountDown = ({ value, className, format = 'mm:ss', onTimeEnd }) => {
    const [times, setTimes] = useState(0)
    useEffect(() => {
        if (value > 0) {
            let _value = parseInt(value)
            setTimes(_value)
            const timer = setInterval(() => {
                // console.log('ZCountDown setInterval:', _value)
                if (_value === 0) {
                    typeof onTimeEnd === 'function' && onTimeEnd()
                    return clearInterval(timer)
                }
                _value -= 1
                setTimes(_value)
            }, 1000)
            return () => {
                clearInterval(timer)
            }
        }
    }, [value])

    const filterTimes = useMemo(() => {
        const d = Math.floor(times / 86400)
        const h = Math.floor((times - d * 86400) / 3600)
        const m = Math.floor((times - d * 86400 - h * 3600) / 60)
        const s = times % 60

        if (format.includes('dd')) {
            return format
                .replace('dd', filterNumber(d))
                .replace('hh', filterNumber(h))
                .replace('mm', filterNumber(m))
                .replace('ss', filterNumber(s))
        }
        if (format.includes('hh')) {
            return format
                .replace('hh', filterNumber(d * 24 + h))
                .replace('mm', filterNumber(m))
                .replace('ss', filterNumber(s))
        }
        return format
            .replace('mm', filterNumber((d * 24 + h) * 60 + m))
            .replace('ss', filterNumber(s))
    }, [times])


    return (
        <span className={`z-count-down ${className || ''}`}>{filterTimes}</span>
    )
}

export default ZCountDown