import React, { useEffect, useRef, useState } from 'react'
import { CalendarPicker, Image, InfiniteScroll } from 'antd-mobile-v5'
import { getRecentData, myActivityList } from '../../../apis/activity'
import { timestampToString } from '../../../utils'

const statusList = [
    { id: 0, name: '全部' },
    { id: 1, name: '待审核' },
    { id: 2, name: '已驳回' },
    // { id: 3, name: '招募中' },
    { id: 4, name: '进行中' },
    // { id: 5, name: '已完成' },
    { id: 6, name: '已结束' },
]

const ActivityList1 = () => {
    const [status, setStatus] = useState(0)
    const page = useRef(1)
    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const [visible, setVisible] = useState(false)
    const [dates, setDates] = useState([null, null])
    const [data, setData] = useState({})

    useEffect(() => {
        page.current = 1
        setList([])
        setHasMore(true)
    }, [status])

    useEffect(() => {
        getData()
    }, [dates])

    const getData = async() => {
        try {
            const params = {}
            if (dates[0] && dates[1]) {
                params.start_time = timestampToString(dates[0], 1)
                params.end_time = timestampToString(dates[1], 1)
            }
            const { data } = await getRecentData(params)
            setData(data)
        } catch (e) {
            console.log(e)
        }
    }

    async function loadMore() {
        try {
            const _page = page.current
            const { data } = await myActivityList({
                page: _page,
                limit: 10,
                status
            })
            setList(_page === 1 ? data.data : [...list, ...data.data])
            setHasMore(data.has_more_pages)
            page.current += 1
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    return (
        <div>
            <div className="info-card">
                <div className="info-card__header flex-space">
                    <div className="info-card__title">近期数据</div>
                    <div className="flex-a-center" onClick={() => setVisible(true)}>
                        <div className="info-card__date flex-a-center">
                            <div className="input">{dates[0] ? timestampToString(dates[0], 1, '.') : '开始时间'}</div>
                            <div>-</div>
                            <div className="input">{dates[1] ? timestampToString(dates[1], 1, '.') : '结束时间'}</div>
                        </div>
                        <div className="info-card__arrow"></div>
                    </div>
                </div>
                <div className="info-card__grids">
                    <div className="item">
                        <div className="item-label">{data.consume_price || 0}</div>
                        <div className="item-text">消费额(元)</div>
                    </div>
                    <div className="item">
                        <div className="item-label">{data.income || 0}</div>
                        <div className="item-text">收入(元)</div>
                    </div>
                    <div className="item">
                        <div className="item-label">{data.share_num || 0}</div>
                        <div className="item-text">分享</div>
                    </div>
                    <div className="item">
                        <div className="item-label">{data.gain_num || 0}</div>
                        <div className="item-text">领券量</div>
                    </div>
                    <div className="item">
                        <div className="item-label">{data.fans_num || 0}</div>
                        <div className="item-text">粉丝量</div>
                    </div>
                    <div className="item">
                        <div className="item-label">{data.finds_num || 0}</div>
                        <div className="item-text">创作裂变</div>
                    </div>
                </div>
            </div>
            <div className="content-card">
                <div className="status-tabs">
                    {statusList.map((item) => (
                        <div
                            key={item.id}
                            className={`item ${status === item.id ? 'active' : ''}`}
                            onClick={() => setStatus(item.id)}
                        >
                            <span className="item-name">{item.name}</span>
                        </div>
                    ))}
                </div>
                <div className="activity-list">
                    {list.map(item => (<div className="item-content" key={item.id}>
                        <div className="flex">
                            <div className="item-media flex-shrink-0">
                                <Image className="item-img" src={item.cover} />
                                {item.heat > 0 && <div className="item-hot flex-a-center">
                                    <div className="icon-hot"></div>
                                    <div>{item.heat}</div>
                                </div>}
                            </div>
                            <div className="item-inner flex-1">
                                <div className="flex-space">
                                    <div className="item-title ell1">{item.title}</div>
                                    {[1, 2].includes(item.status) && <div className="item-status erro">{item.status_html}</div>}
                                    {item.status === 4 && <div className="item-status succ">{item.status_html}</div>}
                                    {item.status === 6 && <div className="item-status">{item.status_html}</div>}
                                </div>
                                {[1,2].includes(item.status)
                                    ? <div className="item-subtl">· {item.publish_date}</div>
                                    : <div className="item-subtl flex-a-center"><span className="icon-time"></span>{item.end_time}结束</div>}
                                {item.status === 2 && <div className="item-desc">{item.examine_msg}</div>}
                                {item.involved_num > 0 && <div className="item-user flex-a-center">
                                    <div className="item-user-imgs">
                                        {item.involved_num > 3 && <div className="item-user-more">
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                            <div className="dot"></div>
                                        </div>}
                                        {item.involved_user_list.map(t => (<img key={t.id} className="item-user-img" src={t.avatar} />))}
                                    </div>
                                    <div className="item-user-text">{item.involved_num}人已参加</div>
                                </div>}
                                {[4,6].includes(item.status) && <div className="item-text">转发{item.share_num} · 裂变{item.create_finds_num} · 观看{item.watch_num}</div>}
                            </div>
                        </div>
                        {[4,6].includes(item.status) && <div className="item-footer flex">
                            <div className="item-tag">
                                <div className="item-tag__text">报名商家<span className="num">{item.store_num}</span></div>
                                <div className="item-tag__line"></div>
                                <div className="item-tag__text">用券量<span className="num">{item.use_coupon_num}</span></div>
                            </div>
                        </div>}
                    </div>))}
                </div>
                <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50} />
            </div>
            <CalendarPicker
                visible={visible}
                selectionMode='range'
                onClose={() => setVisible(false)}
                onMaskClick={() => setVisible(false)}
                onConfirm={val => {
                    setDates(val ? val : [null, null])
                }}
            />
        </div>
    )
}

export default ActivityList1