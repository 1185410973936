import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Image } from 'antd-mobile-v5'
import './index.scss'
import { getStoreJoinList } from '../../apis/activity'
import qs from 'query-string'

const JoinStoreList = (props) => {
    const { push } = useHistory()
    const { id } = qs.parse(useLocation().search)
    const [list, setList] = useState([])
    const [num, setNum] = useState(0)

    useEffect(() => {
        getList()
    }, [])

    async function getList() {
        try {
            const { data } = await getStoreJoinList({
                activity_id: id,
                type: 0
            })
            setList(data.store_list)
            setNum(data.new_num)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="joinStoreList">
            {num > 0 && <div className="tips-box">有{num}个申请待通过</div>}
            <div className="list">
                {list.map(item => (<div className="item-content flex" key={item.id}>
                    <div className="item-media flex-shrink-0">
                        <Image className="item-img" src={item.cover} />
                    </div>
                    <div className="item-inner flex-1">
                        <div className="flex-space">
                            <div className="flex-1">
                                <div className="item-title ell1">{item.store_name}</div>
                                <div className="item-goods flex-a-center">
                                    <div className="item-goods__icon">
                                        <span className="icon-goods1"></span>
                                    </div>
                                    <div className="item-goods__inner">
                                        <div className="item-goods__title">活动商品</div>
                                        <div className="item-goods__subtl">{item.product_count}件商品</div>
                                    </div>
                                </div>
                            </div>
                            {item.store_status === 0 && <div className="item-btn" onClick={() => push(`/storeExamine?id=${item.activity_id}&storeId=${item.store_id}`)}>查看</div>}
                            {item.store_status === 1 && <div className="item-status succ">已通过</div>}
                            {item.store_status === 2 && <div className="item-status erro">已驳回</div>}
                        </div>
                        {item.store_status === 2 && <div className="item-desc">{item.store_examine_msg}</div>}
                    </div>
                </div>))}
            </div>
        </div>
    )
}

export default JoinStoreList