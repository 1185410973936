import React from 'react'
import './index.scss'

const ZFooterBar = (props) => {
    return (
        <div className="z-footer" style={props.style}>
            <div className="z-footer__placeholder"></div>
            <div className={`z-footer__inner flex-space ${props.className || ''}`}>
                {props.children}
            </div>
        </div>
    )
}

export default ZFooterBar