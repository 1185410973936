import React from 'react'
import app from '@/app.json'
import './index.scss'

const emptyImg = app.oss_host + 'static/imagesDZtaro/empty-2.png'

const ZEmpty = ({
    text = '暂无数据',
    style,
    children,
}) => {
    return (
        <div className="z-empty" style={style}>
            <img className="z-empty__img" src={emptyImg} />
            <div className="z-empty__text">{text}</div>
            {children}
        </div>
    )
}

export default ZEmpty