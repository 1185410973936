import React, { useRef, useState } from 'react'
import { Image, InfiniteScroll } from 'antd-mobile-v5'
import './index.scss'
import { getRefundList } from '../../apis/order'

const AfterSalesRecord = (props) => {
    const page = useRef(1)
    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState(true)

    async function loadMore() {
        try {
            const _page = page.current
            const { data } = await getRefundList({
                type: 0,
                status: 2,
                page: _page,
                limit: 10,
            })
            setList(_page === 1 ? data.data : [...list, ...data.data])
            setHasMore(data.has_more_pages)
            page.current += 1
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    return (
        <div className="afterSalesRecord">
            <div className="content">
                {list.map(item => (<div className="card" key={item.id}>
                    <div className="item-header flex-space">
                        <div>{item.created_at}</div>
                        <div>订单编号：{item.order_no}</div>
                    </div>
                    {item.items.map(t => (<div className="item-goods flex" key={t.id}>
                        <div className="item-goods__media flex-shrink-0">
                            <Image className="item-goods__img" src={t.product_cover} />
                        </div>
                        <div className="item-goods__inner flex-1">
                            <div className="item-goods__title ell2">{t.product_name}</div>
                            <div className="item-goods__number">x{t.number}</div>
                        </div>
                    </div>))}
                    <div className="item-inner">
                        <div className="item-userInfo flex-a-center">
                            <img className="userInfo-img" src={item.user_cover} />
                            <span className="userInfo-name">{item.nickname}</span>
                            {!!item.mobile && <a href={`tel:${item.mobile}`} className="flex-a-center">
                                <span className="userInfo-phone">{item.mobile}</span>
                                <span className="icon-phone1"></span>
                            </a>}
                        </div>
                        {item.remark_type === 11
                            ? <div className="item-title">{item.remark_type_text}</div>
                            : <div className="item-title">{item.type === 1 ? '仅退款' : '退货退款'}：{item.remark_type_text}</div>}
                        {item.status === 2 ? <>
                            <div className="item-title">申请退款金额：￥{item.amount}</div>
                            <div className="item-title">损耗扣除金额：￥{item.deduct_price}</div>
                            <div className="flex-a-center">
                                <span>实际退款金额：</span>
                                <span className="item-amount">￥{item.refund_amount}</span>
                            </div>
                        </> : <div className="flex-a-center">
                            <span>退款金额：</span>
                            {item.status === 1 ? <span className="item-amount">￥{item.amount}</span> : <span>￥{item.amount}</span>}
                        </div>}
                    </div>
                    <div className="item-footer flex-space">
                        <div className="item-timer">处理时间：{item.audit_time}</div>
                        {item.status === 2 && <div className="item-btn disabled">已退款</div>}
                        {item.status === 3 && <div className="item-btn disabled">已拒绝</div>}
                        {item.status === 4 && <div className="item-btn disabled">已撤销</div>}
                    </div>
                    {item.status === 3 && <div className="item-desc">驳回理由：{item.refusal_reason}</div>}
                </div>))}
            </div>
            <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50} />
        </div>
    )
}

export default AfterSalesRecord