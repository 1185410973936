import React, { useState } from 'react'
import { Switch } from 'antd-mobile-v5'
import './index.scss'

const SystemNotice = (props) => {
    const [checked1, setChecked1] = useState(true)
    const [checked2, setChecked2] = useState(false)

    return (
        <div className="systemNotice">
            <div className="card">
                <div className="item-content flex-a-center">
                    <div className="icon-orderNotice"></div>
                    <div className="item-inner flex-1">
                        <div className="item-title">订单通知</div>
                        <div className="item-subtl">包含支付、成团、发货、物流、售后等</div>
                    </div>
                    <Switch
                        checked={checked1}
                        onChange={setChecked1}
                        style={{
                            '--adm-color-background': '#DCDFE6',
                            '--adm-color-border': '#DCDFE6',
                            '--checked-color': '#665BD4',
                            '--height': '24px',
                            '--width': '40px',
                        }}
                    />
                </div>
                <div className="item-content flex-a-center">
                    <div className="icon-notice"></div>
                    <div className="item-inner flex-1">
                        <div className="item-title">官方通知</div>
                        <div className="item-subtl">包含活动邀请、退货包运费、推广平台</div>
                    </div>
                    <Switch
                        checked={checked2}
                        onChange={setChecked2}
                        style={{
                            '--adm-color-background': '#DCDFE6',
                            '--adm-color-border': '#DCDFE6',
                            '--checked-color': '#665BD4',
                            '--height': '24px',
                            '--width': '40px',
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default SystemNotice