import { action, observable, makeObservable } from 'mobx'
import { getStoreInfo, setStoreInfo } from '../apis/home'
import { Toast } from 'antd-mobile-v5'

class MainStore {
    info = {};

    constructor() {
        makeObservable(this, {
            info: observable,
            setInfo: action
        })
    }

    setInfo = (v) => {
        this.info = v
    }

    getInfo = async() => {
        try {
            const { data } = await getStoreInfo()
            this.setInfo(data)
        } catch (e) {
            console.log(e)
        }
    }
    updateInfo = async(params = {}) => {
        try {
            await setStoreInfo(params)
            Toast.show('修改成功')
            const { type, ...param } = params
            this.info = {
                ...this.info,
                ...param
            }
        } catch (e) {
            console.log(e)
        }
    }
}

export default new MainStore()