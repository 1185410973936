import React from 'react'
import { Dialog, Toast } from 'antd-mobile-v5'
import { ExclamationCircleFill } from 'antd-mobile-icons'
import app from "../app.json";
export const userAgent = navigator.userAgent;
export const isAndroid =
  userAgent.indexOf("Android") > -1 || userAgent.indexOf("Adr") > -1; //android终端
export const isIOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
export const isMobile = !!userAgent.match(/AppleWebKit.*Mobile.*/); //是否为移动终端
export const isNonEmptyArray = (value) => {
  return Array.isArray(value) && value.length > 0;
};

export const verification = (rule) => {
  for (let item of rule) {
    if (item.rule) {
      Toast.show(item.title);
      return false;
    }
  }
  return true;
};

// 统一社会信用编码
export const checkSocialCreditCode = /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/;

// 身份证
export const isCardNo = (card) => {
  // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
  let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
  if (reg.test(card) === false) {
    return false;
  }
  return true;
};

// 处理传给后台的图片地址
export const handleImg = (urls) => {
  if (urls?.length > 0) {
    const formatList = urls.map((item) =>
      item.url.replace(`${app.oss_host}`, "")
    );
    return urls.length > 1 ? formatList : formatList.join("");
  }
  return ''
};

export const operateImg = (data, thumbUrl, s) => {
  let b = [];
  let a = {};
  a.url = data;
  a.uid = new Date().getTime();
  a.name = new Date().getTime();
  if (thumbUrl) {
    a.thumbUrl = thumbUrl;
  }
  b.push(a);
  s([...b]);
};

export const getQueryString = (name) => {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) return decodeURI(r[2]);
  return null;
};

export const curryingCheck = (reg) => (txt) => reg.test(txt);

export const isPhone = curryingCheck(/^1[123456789]\d{9}$/);

export const dateFormat = (fmt, date) => {
  if (date) {
    let ret;
    const opt = {
      "Y+": date.getFullYear().toString(), // 年
      "M+": (date.getMonth() + 1).toString(), // 月
      "D+": date.getDate().toString(), // 日
      "h+": date.getHours().toString(), // 时
      "m+": date.getMinutes().toString(), // 分
      "s+": date.getSeconds().toString(), // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
        fmt = fmt.replace(
          ret[1],
          ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
        );
      }
    }
    return fmt;
  }
  return "";
};
//字符串判空
export const isEmpty = (obj) => {
  if (typeof obj == "undefined" || obj == null || obj == "") {
    return true;
  } else {
    return false;
  }
};

// 时间格式化
export const YMDHMFormatDate = (input) => {
  if (!input) return "";
  let date = new Date(input * 1000);
  let Y = date.getFullYear() + "-";
  let M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  let D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
  let h =
    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  let m =
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    ":";
  let s =
    (date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds()) + "";

  return Y + M + D + h + m + s;
};
//半隐式登录手机号
export function semiHidingMobile(mobile) {
  if (mobile.length >= 9) {
    let reg = mobile.substring(2, 7);
    mobile = mobile.replace(reg, "*****");
  }
  return mobile;
}

// 生成随机数字
export function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

// 个位数前面补 0
export function padStartNumber(d, len = 2) {
  return String(d).padStart(len, '0')
}

/**
 * 时间戳转日期字符串
 * @param {number} timestamp 时间戳
 * @param {number} type 字符串类型
 * @param {string} dSpace 日期分隔符
 * @param {string} tSpace 时间分隔符
 * @returns {string}
 */
export const timestampToString = (timestamp, type, dSpace = '-', tSpace = ':') => {
  const d = new Date(+timestamp)
  const [Y, M, D, h, m, s] = [
    d.getFullYear(),
    padStartNumber(d.getMonth() + 1),
    padStartNumber(d.getDate()),
    padStartNumber(d.getHours()),
    padStartNumber(d.getMinutes()),
    padStartNumber(d.getSeconds())
  ]
  const obj = {
    0: `${M + dSpace + D}`, // MM-DD
    1: `${Y + dSpace + M + dSpace + D}`, // YYYY-MM-DD
    2: `${Y + dSpace + M + dSpace + D} ${h + tSpace + m}`, // YYYY-MM-DD hh:mm
    3: `${Y + dSpace + M + dSpace + D} ${h + tSpace + m + tSpace + s}`, // YYYY-MM-DD hh:mm:ss
    4: `${h + tSpace + m}`, // hh:mm
    5: `${h + tSpace + m + tSpace + s}`, // hh:mm:ss
  }
  return obj[type]
}

export function showConfirm({ title, content, cancelText = '取消', confirmText = '确定', onConfirm }) {
  return Dialog.confirm({
    header: !!title ? (<div className="flex-center">
      <ExclamationCircleFill
        style={{
          fontSize: 20,
          color: 'var(--adm-color-warning)',
        }}
      />
      <span style={{ color: '#1B1A1E', fontSize: 17, fontWeight: 500, marginLeft: 3 }}>{title}</span>
    </div>) : undefined,
    content: (<div style={{ textAlign: 'center' }}>{content}</div>),
    cancelText: (<span style={{ color: '#63626C', fontSize: 16 }}>{cancelText}</span>),
    confirmText: (<span style={{ color: '#665BD4', fontSize: 16, fontWeight: 400 }}>{confirmText}</span>),
    onConfirm: onConfirm
  })
}

/**
 * 复制文本
 * @param {string} text 要复制的文本
 * @returns {Promise}
 */
async function copyToClipboard(text) {
  if (!navigator.clipboard) {
    // 如果不支持Clipboard API，则使用旧方法尝试复制
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      const successful = document.execCommand('copy');
      document.body.removeChild(textArea);
      return successful;
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
      document.body.removeChild(textArea);
      return false;
    }
  }
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (err) {
    console.error('Error using clipboard.write:', err);
    return false;
  }
}

export const copy = async(text) => {
  const res = await copyToClipboard(text)
  if (res) {
    Toast.show('复制成功')
  }
}

/**
 * 电话号码格式化
 * @param {string} phoneNumber 手机号
 * @returns {string} xxx-xxxx-xxxx
 */
export const formatPhoneNumber = (phoneNumber, str = '-') => phoneNumber.replace(/^(\d{3})(\d{4})(\d{4})$/, `$1${str}$2${str}$3`)


/**
 * 日期字符串转时间戳
 * @param timeStr 日期字符串
 * @param {string} dSpace 日期分隔符
 * @param {string} tSpace 时间分隔符
 * @returns {number}
 */
export function timeStrToTimestamp(timeStr, dSpace = '-', tSpace = ':') {
  const [date, time] = timeStr.split(' ')
  const [y, M, d] = date.split(dSpace).map(_ => parseInt(_, 10))
  const [h, m, s] = time ? time.split(tSpace).map(_ => parseInt(_, 10)) : [0, 0, 0]
  return +new Date(y, M - 1, d, h, m, s)
}

// 验证date字符串 匹配 YYYY-MM-DD
export function isValidDateString(str) {
  const dateReg = /^(\d{4})-(\d{2})-(\d{2})$/
  return dateReg.test(str)
}