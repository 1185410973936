import React, { useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Image, ImageViewer, InfiniteScroll } from 'antd-mobile-v5'
import qs from 'query-string'
import './index.scss'
import { getTaskMessageList } from '../../apis/task'

const TaskChat = (props) => {
    const { id } = qs.parse(useLocation().search)
    const page = useRef(1)
    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState(true)

    async function loadMore() {
        try {
            const _page = page.current
            const { data } = await getTaskMessageList({
                order_id: id,
                page: _page,
                limit: 10,
            })
            setList(_page === 1 ? data.data : [...list, ...data.data])
            setHasMore(data.has_more_pages)
            page.current += 1
        } catch (e) {
            console.log(e)
            throw new Error(e)
        }
    }

    return (
        <div className="taskChat">
            <div className="content">
                <div className="comment-list">
                    {list.map(item => (<div className="item" key={item.id}>
                        <div className="item-time">
                            <div className="item-time__text">{item.created_at}</div>
                        </div>
                        <div className={`item-content ${item.is_publish_user === 1 ? 'isMe' : ''}`}>
                            <div className="item-media flex-shrink-0">
                                <Image className="item-avatar" src={item.avatar} />
                            </div>
                            <div className="item-inner">
                                <div className="item-name">{item.nickname}({item.is_publish_user === 1 ? '发布人' : '接单人'})</div>
                                {item.is_image == 0 && <div className="item-input">{item.content}</div>}
                                {item.is_image == 1 && <div className="item-input">
                                    <Image
                                        className="item-img"
                                        src={item.content}
                                        width={125}
                                        height={'auto'}
                                        onClick={() => {
                                            ImageViewer.show({ image: item.content })
                                        }}
                                    />
                                </div>}
                            </div>
                        </div>
                    </div>))}
                </div>
                <InfiniteScroll loadMore={loadMore} hasMore={hasMore} threshold={50} />
            </div>
        </div>
    )
}

export default TaskChat